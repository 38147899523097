const PageView = url => {
  console.log("PageView " + url);
  const dataLayer = window.dataLayer || [];
  if(dataLayer) {
    dataLayer.push({
      'event': 'pageview',
      'category': 'pageview',
      'action': url,
      'Page URL': url,
      'Page Path' : url,
      'Page Hostname' : 'localhost'
    })
  }
}

export default PageView
