import React from 'react'

const SkillsListBlockSkill = ({ preTitle, title }) => {
  return (
    <div className="skills-list-block-skill">
      {preTitle && <h3>{preTitle}</h3>}
      {title && <h4>{title}</h4>}
    </div>
  )
}

export default SkillsListBlockSkill
