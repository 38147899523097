import React from 'react'
import RichText from '../../RichText'

const RichTextBlock = ({ content }) => (
  <div className="rich-text-block">
    <RichText content={content} />
  </div>
)

export default RichTextBlock
