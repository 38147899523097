import getFlattenedEntry from './get-flattened-entry'
import getIn from 'lodash-es/get'

const getFlattenedImageEntry = imageEntry => {
  const image = getFlattenedEntry(imageEntry)
  const { description: alt = '' } = image
  const url = getIn(image, ['file', 'url'], '')
  return { url, alt }
}

export default getFlattenedImageEntry
