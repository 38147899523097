import React from 'react'
import Logo from '../../Logo'
import Link from '../link/Link'
import getImageEntryBackgroundStyle from '../../../selectors/entry/get-image-entry-background-style'
import { withRouter } from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import DesktopBackground from './DesktopBackground'
import ContinueButton from '../../ContinueButton'

const HeroBlock = ({
  header,
  subheading,
  button1: button1Entry,
  desktopBackground,
  mobileBackground,
  location,
}) => {
  const isHomePage = location.pathname === '/'
  const button1 = button1Entry
  const mobileBackgroundStyle = getImageEntryBackgroundStyle(mobileBackground)

  return (
    <div className="hero-block">
      <DesktopBackground
        isHomePage={isHomePage}
        desktopBackground={desktopBackground}
      >
        <div className="hero-mobile-background" style={mobileBackgroundStyle}>
          <div className="hero-block-content">
            <Logo />
            {header && <h1>{header}</h1>}
            {subheading && <h2>{subheading}</h2>}
            <div className="hero-buttons">
              <Link className='begin-story-button' {...button1}>
                <span className="darkblue-right-arrow">
                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="hero-arrow" d="M17.2 7.3L9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3C7.9 0.7 7.9 1.3 8.3 1.7L14 7H1C0.4 7 0 7.4 0 8C0 8.6 0.4 9 1 9H14L8.4 14.3C8 14.7 8 15.3 8.4 15.7C8.5 15.9 8.7 16 9 16C9.2 16 9.5 15.9 9.7 15.7L17.2 8.7C17.4 8.5 17.5 8.2 17.5 8C17.5 7.8 17.4 7.5 17.2 7.3Z" fill="#141E29"/>
                  </svg>
                </span>
              </Link>
              <ContinueButton />
            </div>
            {isHomePage && (
              <AnchorLink className="learn-more" href="#info-block">
                <p>Learn more</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.4"
                  height="19"
                >
                  <path
                    d="M17 9c-.4-.4-1-.4-1.4 0l-5.9 6.4V1c0-.6-.4-1-1-1s-1 .4-1 1v14.5l-6-6.4c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4L8 18.7c.1.2.4.3.7.3s.5-.1.7-.3l7.7-8.2c.4-.4.3-1.1-.1-1.5z"
                    fill="#131e29"
                  />
                </svg>
              </AnchorLink>
            )}
          </div>
        </div>
      </DesktopBackground>
    </div>
  )
}

export default withRouter(HeroBlock)
