import React from 'react'
import MetaTags from '../components/MetaTags'

function Page404() {
  return (
    <div className="page top-padding">
      <MetaTags metaTitle="404" metaDescription="Error 404" />
      <div className="page-content">
        <div className="block-wrapper page404">
          <h1>404 - Page Not Found</h1>
          <p>Sorry, the page you are looking for cannot be found.</p>
        </div>
      </div>
    </div>
  )
}

export default Page404
