import React, { useState } from 'react'
import RichText from './RichText'
import getFlattenedEntries from '../selectors/entry/get-flattened-entries'

const ResourceGrid = ({
  resources,
  skillsToDevelop,
  initialSize = 8,
  stepSize = 8,
  hideSkillNumbers,
}) => {
  const [size, setSize] = useState(initialSize)

  return (
    <div className="resourceGridWrapper">
      <div className="resourceGrid">
        {resources
          .slice(0, size)
          .map(({ title, type, url, description, skills }, index) => {
            const resourceSkills = getFlattenedEntries(skills)
            return (
              <a
                href={url}
                className="gridItem"
                key={`scenarioResource-${index}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="resource-type">{type}</span>
                <span className="resource-title">{title}</span>

                <div className="resource-description">
                  <RichText content={description} />
                </div>

                <div className="resource-footer">
                  <div>
                    {resourceSkills
                      .filter(resourceSkill => {
                        if (hideSkillNumbers) return false

                        if (skillsToDevelop && skillsToDevelop.length > 0) {
                          let showSkill = false
                          skillsToDevelop.forEach(skillToDevelop => {
                            if (
                              skillToDevelop.name === resourceSkill.preTitle
                            ) {
                              showSkill = true
                            }
                          })

                          return showSkill
                        } else {
                          return true
                        }
                      })
                      .map((skill, index) => {
                        return (
                          <span
                            className={`skillNumber skill-${skill.number}`}
                            key={`skillNumber-${index}`}
                          >
                            {skill.number}
                          </span>
                        )
                      })}
                  </div>
                  <div>
                    <span className="resource-cta">
                      {type === 'Video' && <>Watch</>}
                      {type === 'Article' && <>Read</>}
                      {type === 'Website' && <>Explore</>}
                      {type === 'Infographic' && <>View</>}
                      {type === 'Course' && <>Learn</>}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9.3"
                        height="8.5"
                      >
                        <path d="M9.1 3.9L5.1.2c-.2-.3-.5-.2-.7 0-.2.2-.2.5 0 .7l3.1 2.9h-7c-.3-.1-.5.2-.5.4s.2.5.5.5h7L4.4 7.6c-.2.2-.2.5 0 .7.1.1.2.2.4.2.1 0 .2 0 .3-.1l4-3.7c.1-.1.2-.2.2-.4s-.1-.3-.2-.4z" />
                      </svg>
                    </span>
                  </div>
                </div>
              </a>
            )
          })}
      </div>

      {resources.length > 0 && resources.length > size && (
        <button
          className="resource-see-more outline"
          onClick={() => {
            setSize(size + stepSize)
          }}
        >
          See more
        </button>
      )}
    </div>
  )
}

export default ResourceGrid
