import React from 'react'
import RichText from '../../RichText'

const Step = ({ content, number }) => (
  <div className="step">
    <div className="step-number">{number}</div>
    <RichText content={content} />
  </div>
)

export default Step
