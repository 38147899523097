import React, { useEffect, useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { GlobalContext } from '../context/GlobalContext'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import SimulationHeader from '../components/SimulationHeader'
import ContinueModal from '../components/ContinueModal'
import Illustration from '../components/Illustration'

function Instructions({ history }) {
  const { state, actions } = useContext(GlobalContext)

  const [consent, setConsent] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showContinueModal, setShowContinueModal] = useState(false)

  // controls which header/footer show up
  useEffect(() => {
    actions.setIsSimulation(false)
    actions.setShowBackButton(true)
  }, [actions])

  // continue button
  const goToSimulation = () => {
      history.push('/simulation')
      actions.setFoundScenario({})
      actions.setLocalStorageScenarios([])

      actions.setOnEnding(false) 
      actions.setUserRole('')
      actions.setUserConcern('')
      actions.setSkills([])
      actions.setMaxScore(null)
      actions.setScoring(null)
      actions.setProgression(0)
      actions.clearCompletedQuestions()
      actions.nuclearResetScenario()
  }

  // hide error if consent is confirmed
  useEffect(() => {
    if (consent) {
      setShowError(false)
    }
  }, [consent])

  // Has user tried this scenario before?
  useEffect(() => {
    if (
      !_.isEmpty(state.foundScenario) &&
      state.foundScenario.currentQuestion
    ) {
      setShowContinueModal(true)
    } else {
      setShowContinueModal(false)
    }
  }, [state])


  // get illustration image from contentful
  const image = state.extraContent.image && state.extraContent.image.fields.file.url;
  const imageDescription = state.extraContent.image && state.extraContent.image.fields.description;

  return (
    <div>
      <SimulationHeader />

      <div className="instructions-page">
        <Helmet>
            <title>Natural Supports | Instructions</title>
            <meta
              name="description"
              content="Natural Supports Simulation Instructions"
            />
          </Helmet>
        <div className="page-content">
          <div className="container">
            <div className="intro-info-boxes">
              <div className="instructions-illustration">
                {state.extraContent.image && (<Illustration url={image} alt={imageDescription} />)}
              </div>
              <h1>{state.extraContent.instructionsTitle}</h1>
              <div className="instructions-intro-text">
                  {state.extraContent.instructionsIntroText && (
                    <p>{state.extraContent.instructionsIntroText}</p>
                  )}
              </div>
              <div className="instructions-time-text">
                  {state.extraContent.instructionsTimeText && (
                    <p>{state.extraContent.instructionsTimeText}</p>
                  )}
              </div>
            </div>

            <div className="cta-block">
              <div className="begin-story-block instructions">
                {state.extraContent.beginStoryCta&& (
                  <button
                    className="begin-story-button"
                    onClick={goToSimulation}
                  >
                    {state.extraContent.beginStoryCta}

                    <span className="darkblue-right-arrow">
                      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="instructions-arrow" d="M17.2 7.3L9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3C7.9 0.7 7.9 1.3 8.3 1.7L14 7H1C0.4 7 0 7.4 0 8C0 8.6 0.4 9 1 9H14L8.4 14.3C8 14.7 8 15.3 8.4 15.7C8.5 15.9 8.7 16 9 16C9.2 16 9.5 15.9 9.7 15.7L17.2 8.7C17.4 8.5 17.5 8.2 17.5 8C17.5 7.8 17.4 7.5 17.2 7.3Z" fill="#141E29"/>
                      </svg>
                    </span>
                  </button>
                )}
              
              
              {state.extraContent.reviewSupportiveSkillsCta && ( 
                <button
                  className="review-supportive-skills-button instructions"
                  onClick={() => history.push('/resources')}
              >
                  <p>{state.extraContent.reviewSupportiveSkillsCta}</p>
                </button>
              )}
              </div>
            </div>
            
            <div className="disclaimer-block">
              {state.extraContent.disclaimerText && (               
                <p>{state.extraContent.disclaimerText}</p> 
              )}
            </div> 
          </div>

          {/* Check if the user has left off on this secnario before. If they have, give them the option of continuing from where they left off. */}
          {showContinueModal && <ContinueModal history={history} isOpen={true} />}

        </div>
      </div>
    </div>
  )
}

export default withRouter(Instructions)
