import React, { useState, useContext, useEffect } from 'react'
import Modal from 'react-modal'
import RichText from './RichText'
import { GlobalContext } from '../context/GlobalContext'

Modal.setAppElement('#root')

function Interstitial({ preHeadline, headline, content, showAfterQuestion }) {
  // context
  const { state } = useContext(GlobalContext)

  // state
  const [modalOpen, setModalOpen] = useState(false)
  const [hasShownThis, setHasShowThis] = useState(false)

  // close modal
  const closeModal = () => {
    setModalOpen(false)
  }

  // open modal after certain question
  useEffect(() => {
    if (state.progression === showAfterQuestion + 1 && !hasShownThis) {
      setModalOpen(true)
      setHasShowThis(true)
    }
  }, [hasShownThis, showAfterQuestion, state.progression])

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      contentLabel="Interstitial Modal"
      className="modal interstitial-modal"
      overlayClassName="modal-overlay"
      shouldCloseOnOverlayClick={true}
    >
      <div className="modal-header">
        <button onClick={closeModal} className="close-modal">
          <svg xmlns="http://www.w3.org/2000/svg" width="18.1" height="17">
            <path
              d="M10.5 8.5l7.2-6.8c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L9 7.1 1.7.3C1.3-.1.7-.1.3.3c-.4.4-.4 1 0 1.4l7.2 6.8-7.2 6.8c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.2 0 .5-.1.7-.3L9 9.9l7.3 6.9c.2.2.4.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-7.2-6.9z"
              fill="#131e29"
            />
          </svg>
          <span className="visually-hidden">close</span>
        </button>
      </div>

      <div className="modal-body">
        {preHeadline && <p className="interstitial-header">{preHeadline}</p>}
        {headline && <h1>{headline}</h1>}
        <RichText content={content} />
        <button onClick={closeModal} className="primary">
          Continue
        </button>
      </div>
    </Modal>
  )
}

export default Interstitial
