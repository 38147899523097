import React, { Fragment, useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { GlobalContext } from '../context/GlobalContext'
import ContentBlock from '../components/content-blocks/ContentBlock'
import getPageByUrl from '../selectors/page/get-page-by-url'
import getImageEntryBackgroundStyle from '../selectors/entry/get-image-entry-background-style'
import kebabCase from 'lodash-es/kebabCase'
import MetaTags from '../components/MetaTags'
import Surveys from '../components/Surveys'

const Page = ({ location }) => {
  const { state, actions } = useContext(GlobalContext)
  const { pathname: url } = location
  const page = getPageByUrl(state, url)
  // controls which header/footer show up
  useEffect(() => {
    actions.setIsSimulation(false)
    actions.setHideFooter(false)
  }, [actions])
  const {
    contentBlocks = [],
    title,
    style,
    bottomLeftBackground,
    metaTitle,
    metaDescription,
    topPadding = false,
  } = page
  const backgroundStyle = getImageEntryBackgroundStyle(bottomLeftBackground)
  const paddingClassName = topPadding ? 'top-padding' : ''
  const resourcesPage = page.entryTitle === 'Resources Page'
  const className = resourcesPage ? `page ${kebabCase(style)} ${paddingClassName} resources-page` : `page ${kebabCase(style)} ${paddingClassName}`
  const { hasPagesLoaded } = state

  if (!hasPagesLoaded) {
    return null
  }

  return (
    <Fragment>
      <MetaTags metaTitle={metaTitle} metaDescription={metaDescription} />
      <div className={className} style={backgroundStyle}>
        <div className={page.entryTitle === "Homepage" ? "page-content homepage" : "page-content"}>
          <div className="block-wrapper">
            <div className="page-title-wrapper">
              {title && <h1 className="page-title">{title}</h1>}
            </div>
          </div>
          <div className="content-blocks">
            {contentBlocks.map((contentBlock) => (
              <div key={contentBlock.id} className={`block-wrapper ${contentBlock.type}-wrapper`}>
                <ContentBlock type={contentBlock.type} fields={contentBlock} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Surveys />
    </Fragment>
  )
}

export default withRouter(Page)
