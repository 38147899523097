const Completion = (average, scores, scenarioIdentifier) => {
  let scorePercents = []
  scores.forEach(score => {
    scorePercents.push(score.percent.toFixed(1))
  })
  const dataLayer = window.dataLayer || [];
  if(dataLayer) {
    dataLayer.push({
      'event': 'completion',
      'category': 'completion',
      'action': scenarioIdentifier + ' - Average score: ' + average.toFixed(1),
      'label': 'Scores: ' + scorePercents.join(",")
    })
  }
}

export default Completion
