import React, { useState, useEffect, useContext } from 'react'
import slugify from 'slugify'
import { GlobalContext } from '../context/GlobalContext'
import { withRouter } from 'react-router'
import _ from 'lodash'

function ContinueButton({ history }) {
  const [lastVisitedScenario, setLastVisitedScenario] = useState({})
  const { actions } = useContext(GlobalContext)

  const handleClick = event => {
    history.push(
      `/simulation/${slugify(lastVisitedScenario.role)}/${slugify(
        lastVisitedScenario.concern
      )}`
    )
  }

  useEffect(() => {
    if (actions) {
      setLastVisitedScenario(actions.loadLastVisitedScenario())
    }
  }, [actions])

  return (
    <>
      {!_.isEmpty(lastVisitedScenario) ? (
        <button className="button secondary continue" onClick={handleClick}>
          Continue Story
        </button>
      ) : null}
    </>
  )
}

export default withRouter(ContinueButton)
