import React, { useState, useRef, useEffect } from 'react'
import AudioEvent from '../selectors/analytics/audio-event';

const AudioSample = ({ audioClip, text }) => {
  const [audioIsPlaying, setAudioIsPlaying] = useState(false);

  const analytics = useRef({
    start: {
      sent: false,
      sender: () => AudioEvent('0', audioClip.fields )
    },
    quarter: {
      sent: false,
      sender: () => AudioEvent('25', audioClip.fields )
    },
    half: {
      sent: false,
      sender: () => AudioEvent('50', audioClip.fields )
    },
    threeQuarter: {
      sent: false,
      sender: () => AudioEvent('75', audioClip.fields )
    },
    complete: {
      sent: false,
      sender: () => AudioEvent('100', audioClip.fields )
    },
  });

  function analyticsEvent(type) {
    if (analytics.current[type].sent) {
      return;
    } else {
      analytics.current[type].sender();
      analytics.current[type].sent = true;
    }
  }

  function resetEvents() {
    // reset all the sents to capture events on repeated plays
    Object.entries(analytics.current).forEach(([key, value]) => {
      value.sent = false;
    });
  }

  // Get audio from contentful and play/pause
  let audioRef = useRef(new Audio(audioClip && audioClip.fields && audioClip.fields.file.url))
  const handleAudioPlay = () => {
    if (audioRef.current.currentTime === 0) {
      analyticsEvent('start');
    }
    setAudioIsPlaying(true)
    audioRef.current.play()
  }

  const handleAudioPause = () => {
    setAudioIsPlaying(false)
    audioRef.current.pause()
  }

  // Play/Pause SVGs
  let playSvg = <svg className="audio-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 14.5V5.5L14 10L8 14.5Z" fill="#00D3E3"/>
  </svg>

  let pauseSvg = <svg className="audio-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0Z" fill="#00D3E3"/>
  <rect x="7" y="6" width="2" height="8" fill="#EFFAFC"/>
  <rect x="11" y="6" width="2" height="8" fill="#EFFAFC"/>
  </svg>

  const handleAudioEnd = () => {
    analyticsEvent('complete');
    setAudioIsPlaying(false);
    resetEvents();
    audioRef.current.currentTime = 0;
  }

  const handleTimeUpdate = () => {
    const time = audioRef.current.currentTime;
    const duration = audioRef.current.duration;
    const completedPercent = time / duration * 100.0;
    if (completedPercent > 25) {
      analyticsEvent('quarter');
    }

    if (completedPercent > 50) {
      analyticsEvent('half');
    }

    if (completedPercent > 75) {
      analyticsEvent('threeQuarter');
    }
  }

  // add and remove event listeners in useEffect:
  useEffect(() => {
    // Add event listener to audio, so that when it ends, the play button shows again
    audioRef.current.addEventListener('ended', handleAudioEnd);
    audioRef.current.addEventListener('timeupdate', handleTimeUpdate);

    return function cleanup() {
      audioRef.current.removeEventListener('ended', handleAudioEnd);
      audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, []);

  return (
    <>
      {audioClip && audioClip.fields &&  (
        <div className="simulation-audio">
          <button className="audio-play" onClick={audioIsPlaying ? handleAudioPause : handleAudioPlay} aria-label="Text To Speech">
            <p>{text}</p>
            <div className="audio-icon-wrapper">
              {audioIsPlaying ? pauseSvg : playSvg}
            </div>
          </button>
          <audio src={audioClip.fields.file.url} type='audio/mp3' ref={audioRef}></audio>
        </div>
      )}
    </>
  )
}

export default AudioSample;
