import React, { useContext, useState, useEffect } from 'react'
import { GlobalContext } from '../context/GlobalContext'
import { withRouter, Link, Prompt } from 'react-router-dom'
import SimulationExit from '../selectors/analytics/simulation-exit'
import ProgressBar from './ProgressBar'
import Modal from 'react-modal'

Modal.setAppElement('#root')

function SimulationHeader({ history, currentScenario }) {
  const { actions } = useContext(GlobalContext)
  const { state } = useContext(GlobalContext)

  // modal open state
  const [modalIsOpen, setModalIsOpen] = useState(false)
  // block navigation
  const [blockNav, setBlockNav] = useState(false)

  // open exit modal
  const openModal = () => {
    setModalIsOpen(true)
  }

  // close exit modal
  const closeModal = () => {
    setModalIsOpen(false)
  }

  // Send analytics data about when the user exited and
  // show the exit survey
  const showExitSurvey = () => {
    let exitQuestion

    if (!state.foundScenario.currentQuestion) {
      exitQuestion = 'Introduction'
    } else if (state.foundScenario.currentQuestion && state.onEnding) {
      exitQuestion = 'Ending'
    } else if (state.foundScenario.currentQuestion) {
      exitQuestion = state.foundScenario.currentQuestion
    }

    SimulationExit(history.location.pathname, exitQuestion)
    actions.setShowExitSurvey(true)
  }

  // show prompt when trying to navigate away from simulation unless exit modal is open
  useEffect(() => {
    if (modalIsOpen || state.showBackButton || state.onEnding) {
      setBlockNav(false)
    } else {
      setBlockNav(true)
    }
  }, [modalIsOpen, state.onEnding, state.showBackButton])

  return (
    <div className="simulation-header">
      <Prompt
        when={blockNav}
        message={() =>
          `Are you sure you want to go to back? If you leave you can pick up where you left off by coming back to this scenario.`
        }
      />

      {state.showBackButton && (
        <button className="back-button" onClick={() => history.goBack()}>
          <div className="arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="9.8" height="8.9">
              <path
                d="M9.3 4H1.8L5.1.9c.2-.2.2-.5 0-.7-.2-.2-.5-.3-.7-.1l-4.2 4c-.1.1-.2.2-.2.4s.1.3.2.4l4.2 4c.1.1.2.1.3.1.1 0 .3-.1.4-.2.2-.2.2-.5 0-.7L1.8 5h7.5c.3 0 .5-.2.5-.5S9.6 4 9.3 4z"
                fill="#131e29"
              />
            </svg>
          </div>
          <span className="text">Back</span>
        </button>
      )}

      <div className="container">
        {currentScenario && state.completedQuestions && (
          <ProgressBar
            currentScenario={currentScenario}
          />
        )}
        
        <p className="role-concern">
          {state.userRole && <span>{state.userRole}</span>}
          {state.userConcern && <span>, {state.userConcern}</span>}
        </p>
      

        {state.isSimulation && (
          <button className="exit-button" onClick={openModal}>
            <svg xmlns="http://www.w3.org/2000/svg" width="19.7" height="14.2">
              <path
                d="M8.1 12.2H2V2h6.1c.6 0 1-.4 1-1s-.4-1-1-1H0v14.2h8.1c.6 0 1-.4 1-1s-.4-1-1-1z"
                fill="#131e29"
              />
              <path
                d="M19 7.8l.7-.7-.7-.7-5.1-5.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.4 3.4H8.1c-.6 0-1 .4-1 1s.4 1 1 1h7.8l-3.4 3.4c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3L19 7.8z"
                fill="#131e29"
              />
            </svg>
            Exit
          </button>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Exit Simulation Modal"
        className="modal exit-modal"
        overlayClassName="modal-overlay"
        shouldCloseOnOverlayClick={true}
      >
        <div className="modal-header">
          <button onClick={closeModal} className="close-modal">
            <svg xmlns="http://www.w3.org/2000/svg" width="18.1" height="17">
              <path
                d="M10.5 8.5l7.2-6.8c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L9 7.1 1.7.3C1.3-.1.7-.1.3.3c-.4.4-.4 1 0 1.4l7.2 6.8-7.2 6.8c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.2 0 .5-.1.7-.3L9 9.9l7.3 6.9c.2.2.4.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-7.2-6.9z"
                fill="#131e29"
              />
            </svg>
            <span className="visually-hidden">close</span>
          </button>
          {state.extraContent.exitModalHeader && (
            <h2>{state.extraContent.exitModalHeader}</h2>
          )}
        </div>

        <div className="modal-body">
          {state.extraContent.exitModalText && (
            <p>{state.extraContent.exitModalText}</p>
          )}

          <div className="buttons">
            {state.extraContent.surveyLink && (
              <Link to="/" onClick={showExitSurvey} className="primary">
                Yes
              </Link>
            )}
            <button className="primary" onClick={closeModal}>
              No
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default withRouter(SimulationHeader)
