import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import { Helmet } from 'react-helmet';
import { GlobalContext } from '../context/GlobalContext';
import { withRouter, Link, useParams } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import slugify from 'slugify';
import { unslugify } from 'unslugify';
import SimulationHeader from '../components/SimulationHeader';
import Question from '../components/Question';
import ProgressBar from '../components/ProgressBar';
import Interstitial from '../components/Interstitial'
import Illustration from '../components/Illustration';
import AudioSample from '../components/AudioSample';

import { TransitionGroup, CSSTransition } from 'react-transition-group';

function Simulation({ history }) {
  const [currentScenario, setCurrentScenario] = useState(null)
  const [showIntro, setShowIntro] = useState(true)
  const [questions, setQuestions] = useState({})

  const { state, actions } = useContext(GlobalContext)

  // enter scenario after the introduction
  const beginScenario = () => {
    setShowIntro(false)
    actions.setProgression(1)

    // reset scoring
    actions.setSkills([])
    actions.setMaxScore([])
    actions.setScoring([])
  }

  // Check if URL params are being sent to load the simulation directly
  let { paramRole, paramConcern } = useParams()

  // controls which header/footer show up
  useEffect(() => {
    actions.setIsSimulation(true)
    actions.setShowBackButton(false)
  }, [actions])

  // pick the current scenario based on role/concern from URL params
  useEffect(() => {
    if (paramRole && paramConcern) {
      const unslugParamRole = unslugify(paramRole)
        .toLowerCase()
        .trim()
      const unslugParamConcern = unslugify(paramConcern)
        .toLowerCase()
        .trim()

      actions.setUserRole(unslugParamRole)
      actions.setUserConcern(unslugParamConcern)
      actions.saveLastVisitedScenario(unslugParamRole, unslugParamConcern)
      const foundScenario = state.scenarios.find(scenario => {
        return unslugify(scenario.role.fields.role)
            .toLowerCase()
            .trim() === unslugParamRole &&
          unslugify(scenario.concern.fields.name)
            .toLowerCase()
            .trim() === unslugParamConcern
      })

      if (foundScenario) {
        setCurrentScenario(foundScenario)
        setQuestions(foundScenario.questions)
      }
    } else if (state.userRole !== '' && state.userConcern !== '') {
      actions.saveLastVisitedScenario(
        state.userRole.toLowerCase().trim(),
        state.userConcern.toLowerCase().trim()
      )

      // User has clicked through the steps, set the URL accordingly so we can deep link
      history.replace(
        `/simulation/${slugify(state.userRole.toLowerCase().trim())}/${slugify(state.userConcern.toLowerCase().trim())}`
      )
    } else {
      // redirect to beginning if role/concern hasn't been picked
      history.replace('/choose-role')
    }
  }, [state, history, actions, paramRole, paramConcern])

  // If a scenario is found, load the skills, maxScore, scoring, progression
  // Don't show the intro and load the question the user was last on
  useEffect(() => {
    if (!_.isEmpty(state.foundScenario) && !state.onEnding) {
      if (state.foundScenario.currentSkills) {
        actions.setSkills(state.foundScenario.currentSkills)
      }

      if (state.foundScenario.currentMaxScore) {
        actions.setMaxScore(state.foundScenario.currentMaxScore)
      }

      if (state.foundScenario.currentScoring) {
        actions.setScoring(state.foundScenario.currentScoring)
      }

      if (state.foundScenario.currentQuestion) {
        setShowIntro(false)
        actions.setProgression(state.foundScenario.currentProgression)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.foundScenario, state.onEnding])

  useEffect(() => {
    if (state.onEnding) {
      setShowIntro(false)
    }
  }, [state.onEnding])

  return (
    <div className="simulation-wrapper">
      <SimulationHeader className='simulation-header' currentScenario={currentScenario} />

      <div className="simulation">
        <Helmet>
          <title>Natural Supports | Simulation</title>
          <meta name="description" content="Natural Supports Simulator" />
        </Helmet>

        {currentScenario && (
          <TransitionGroup>
            <CSSTransition classNames="fade" timeout={500}>
              <div>
                {showIntro && (
                  <div className="introduction">
                    <div className="container">
                      {currentScenario.introductionTitle && (
                        <h1>{currentScenario.introductionTitle}</h1>
                      )}

                      {currentScenario.audio && (
                        <AudioSample
                          audioClip={currentScenario.audio}
                          text="Listen to text"
                        />
                      )}
                      
                      <div className="content-container">
                        <div className="simulation-intro-image">
                          {currentScenario.image && (
                            <Illustration url={currentScenario.image.fields.file.url} alt={currentScenario.image.fields.title} />
                          )}
                        </div>
                          {documentToReactComponents(
                            currentScenario.introduction
                          )}
                      </div>

                      <div className="answer-intro"></div>
                     
                      <div className="button-wrapper">
                        <button
                          onClick={beginScenario}
                          className="primary begin-btn has-arrow"
                        >
                          Next
                          <div className="arrow">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17.5"
                              height="16"
                            >
                              <path id="simulation-arrow" d="M17.2 7.3L9.7.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4L14 7H1c-.6 0-1 .4-1 1s.4 1 1 1h13l-5.6 5.3c-.4.4-.4 1 0 1.4.1.2.3.3.6.3.2 0 .5-.1.7-.3l7.5-7c.2-.2.3-.5.3-.7s-.1-.5-.3-.7z" />
                            </svg>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {!showIntro && (
                  <Question
                    scenarioHeader={currentScenario.scenarioHeader}
                    selectResponseText={currentScenario.selectResponseText}
                    questions={questions}
                    showIntro={showIntro}
                    longestPath={currentScenario.longestPath}
                    badEnding={currentScenario.badEnding}
                    okEndingScenarioAudio={currentScenario.okEndingScenarioAudio}
                    goodEnding={currentScenario.goodEnding}
                    goodEndingScenarioAudio={currentScenario.goodEndingScenarioAudio}
                    bestEnding={currentScenario.bestEnding}
                    bestEndingScenarioAudio={currentScenario.bestEndingScenarioAudio}
                    endingSummary={currentScenario.endingSummary}
                    history={history}
                  />
                )}

                {currentScenario.interstitials &&
                  currentScenario.interstitials.map((popup, i) => {
                    return (
                      popup.fields && (
                        <Interstitial
                          key={`interstitial-${i}`}
                          preHeadline={popup.fields.preHeadline}
                          headline={popup.fields.headline}
                          content={popup.fields.content}
                          showAfterQuestion={popup.fields.showAfterQuestion}
                        />
                      )
                    )
                  })}
              </div>
            </CSSTransition>
          </TransitionGroup>
        )}
        {!currentScenario && !paramRole && !paramConcern && (
          <div className="container not-found">
            <p>
              No simulation found. <Link to="/choose-role">Try again</Link>.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default withRouter(Simulation)
