import React, { useContext, useState, useRef, useEffect } from 'react'
import { GlobalContext } from '../context/GlobalContext'
import getFlattenedEntries from '../selectors/entry/get-flattened-entries'
import Link from '../components/content-blocks/link/Link'
import LocationChooser from '../components/LocationChooser'

const Header = () => {
  const { state } = useContext(GlobalContext)
  const { headerFooter } = state
  const { mainNavLinks = [], applicationTitle = '', donateLink } = headerFooter
  const [navOpen, setNavOpen] = useState(false)

  const node = useRef()
  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    setNavOpen(false)
  }
  useEffect(() => {
    if (navOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [navOpen])
  return (
    <React.Fragment>
      <header className="App-header">
        <button className="hamburger" onClick={() => setNavOpen(true)}>
          <span className="visually-hidden">Open Navigation</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="29" height="21">
            <path
              className="st0"
              d="M28 2H1c-.6 0-1-.4-1-1s.4-1 1-1h27c.6 0 1 .4 1 1s-.4 1-1 1zM28 11.5H1c-.6 0-1-.4-1-1s.4-1 1-1h27c.6 0 1 .4 1 1s-.4 1-1 1zM28 21H1c-.6 0-1-.4-1-1s.4-1 1-1h27c.6 0 1 .4 1 1s-.4 1-1 1z"
            />
          </svg>
        </button>

        <div className="container">
          <Link to="/" className="main-link">
            <h1>{applicationTitle}</h1>
          </Link>
        </div>

        <LocationChooser />
        {donateLink && (
          <a
            href={donateLink.fields.url}
            target="_blank"
            rel="noopener noreferrer"
            className="donate-link"
          >
            {donateLink.fields.entryTitle}
          </a>
        )}
      </header>

      <nav className={navOpen ? `nav-popout open` : `nav-popout`} ref={node}>
        <div className="nav-top">
          <button className="close-nav" onClick={() => setNavOpen(false)}>
            <span className="visually-hidden">Close Navigation</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="26.1" height="24.5">
              <path
                d="M14.5 12.3L25.8 1.7c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L13.1 10.9 1.7.3C1.3-.1.7-.1.3.3s-.4 1 0 1.4l11.3 10.5L.3 22.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.2 0 .5-.1.7-.3l11.4-10.6 11.4 10.6c.2.2.4.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4L14.5 12.3z"
                fill="#fff"
              />
            </svg>
          </button>
          <Link to="/" className="main-link" onClick={() => setNavOpen(false)}>
            {applicationTitle}
          </Link>
        </div>

        <div className="header-links">
          <div className="header-links-content">
            {getFlattenedEntries(mainNavLinks).map(({ key, fields }) => (
              <Link key={key} {...fields} onClick={() => setNavOpen(false)} />
            ))}
          </div>
        </div>
      </nav>
    </React.Fragment>
  )
}

export default Header
