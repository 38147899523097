import React from 'react'

const SkillBlockSkillContent = ({ openModal, preTitle, title, id, number }) => {
  return (
    <div className="skill-container">
      <div
        className={`skill-tile skill-${number}`}
        role="button"
        onClick={() => openModal(id)}
        onKeyPress={() => openModal(id)}
        data-skill={`${preTitle} - ${title}`}
        tabIndex={0}
      >
        {preTitle && <h3>{preTitle}</h3>}
        {title && <h4>{title}</h4>}
        <div className="read-more">
          Read More
          <svg xmlns="http://www.w3.org/2000/svg" width="9.3" height="8.5">
            <path d="M9.1 3.9L5.1.2c-.2-.3-.5-.2-.7 0-.2.2-.2.5 0 .7l3.1 2.9h-7c-.3-.1-.5.2-.5.4s.2.5.5.5h7L4.4 7.6c-.2.2-.2.5 0 .7.1.1.2.2.4.2.1 0 .2 0 .3-.1l4-3.7c.1-.1.2-.2.2-.4s-.1-.3-.2-.4z" />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default SkillBlockSkillContent
