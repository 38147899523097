import React from 'react'
import TitleBlock from './title-block/TitleBlock'
import SkillsBlock from './skills-block/SkillsBlock'
import ResourcesBlock from './resources-block/ResourcesBlock'
import ContentRightImageLeft4060Block from './content-left-image-right-40-60-block/ContentRightImageLeft4060Block'
import HeroBlock from './hero-block/HeroBlock'
import InfoBlock from './info-block/InfoBlock'
import StepsBlock from './steps-block/StepsBlock'
import RichTextBlock from './rich-text-block/RichTextBlock'
import SocialBlock from './social-block/SocialBlock'
import SkillsListBlock from './skills-list-block/SkillsListBlock'

const ContentBlock = ({ type, fields }) => {
  switch (type) {
    case 'titleBlock':
      return <TitleBlock {...fields} />
    case 'skillsBlock':
      return <SkillsBlock {...fields} />
    case 'resourcesBlock':
      return <ResourcesBlock {...fields} />
    case 'contentLeftImageRight4060Block':
      return <ContentRightImageLeft4060Block {...fields} />
    case 'heroBlock':
      return <HeroBlock {...fields} />
    case 'infoBlock':
      return <InfoBlock {...fields} />
    case 'stepsBlock':
      return <StepsBlock {...fields} />
    case 'richTextBlock':
      return <RichTextBlock {...fields} />
    case 'socialBlock':
      return <SocialBlock {...fields} />
    case 'skillsListBlock':
      return <SkillsListBlock {...fields} />
    default:
      return null
  }
}

export default ContentBlock
