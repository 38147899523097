import React, { useState, useEffect, useContext, useCallback } from 'react'
import _ from 'lodash'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useParams } from 'react-router-dom'
import { GlobalContext } from '../context/GlobalContext'
import PageView from '../selectors/analytics/page-view'
import Completion from '../selectors/analytics/completion'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import Illustration from './Illustration'
import AudioSample from './AudioSample'

function Question({
  scenarioHeader,
  questions,
  selectResponseText,
  longestPath,
  badEnding,
  goodEnding,
  bestEnding,
  history,
  okEndingScenarioAudio,
  goodEndingScenarioAudio,
  bestEndingScenarioAudio,
}) {
  const [selectedAnswer, setSelectedAnswer] = useState(false)
  const [shuffledResponses, setShuffledResponses] = useState([])
  // overall error state (something wrong in contentful)
  const [errorState, setErrorState] = useState(false)
  // error if next button is clicked before a response
  const [nextError, setNextError] = useState(false)
  // send to end?
  const [sendToEnd, setSendToEnd] = useState(false)
  // show ending
  const [showEnding, setShowEnding] = useState(false)
  const [endingContent, setEndingContent] = useState('')
  const [feedbackContent, setFeedbackContent] = useState('')

  // show correct audio clips based on ending
  const [audioOnEnd, setAudioOnEnd] = useState({})
  const [scenarioAudioOnEnd, setScenarioAudioOnEnd] = useState({})

  // context
  const { actions, state } = useContext(GlobalContext)
  const [currentQuestion, setCurrentQuestion] = useState({})

  const { paramRole, paramConcern } = useParams()

  // grab the first question
  useEffect(() => {
    if (state.foundScenario.currentQuestion) {
      const questionIndex = questions.findIndex(
        question => question.fields.id === state.foundScenario.currentQuestion
      )
      setCurrentQuestion(questions[questionIndex])
      PageView(questions[questionIndex].fields.id)
    } else {
      setCurrentQuestion(questions[0])
    }

    window.scrollTo(0, 0)
  }, [questions, state.foundScenario.currentQuestion])

  // Track what question the user is on to allow them to continue where they left off
  useEffect(() => {
    if (!_.isEmpty(currentQuestion)) {
      actions.setFoundScenario(prevState => ({
        ...prevState,
        currentQuestion: currentQuestion.fields.id,
      }))

      // User finshed the story before but exited on the ending screen, restore the ending
      if (state.foundScenario.currentOnEnding) {
        calculateScore()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion])

  // select an answer
  const selectAnswer = answer => {
    setSelectedAnswer(answer)
    setNextError(false)
  }

  // save the user score
  const saveScore = sendToEnd => {
    const skillsArray = []
    currentQuestion.fields.skills.forEach(skill => {
      skillsArray.push(skill.fields.preTitle)
    })
    actions.setSkills([...state.skills, skillsArray])
    actions.setMaxScore([
      ...state.maxScore,
      currentQuestion.fields.answers.length,
    ])
    actions.setScoring([...state.scoring, selectedAnswer.fields.score])


    actions.addCompletedQuestion(currentQuestion)

    setSendToEnd(sendToEnd)
  }

  // calculate the score and send to end
  const calculateScore = useCallback(() => {
    const scoreArray = []

    // create scoring object which tracks best possible score and user score for each skill
    state.skills.forEach(skillArray => {
      skillArray.forEach(skill => {
        let skillSaved = false
        scoreArray.forEach(score => {
          if (score.name === skill) {
            skillSaved = true
          }
        })
        if (skillSaved === false) {
          scoreArray.push({
            name: skill,
            highest: 0,
            score: 0,
            percent: 0,
          })
        }
      })
    })

    // calculate highest score for each skill
    state.skills.forEach((skillArray, i) => {
      let maxScore = state.maxScore[i]
      skillArray.forEach(skill => {
        scoreArray.forEach(score => {
          if (score.name === skill) {
            score.highest += maxScore
          }
        })
      })
    })

    // add up user scores
    state.skills.forEach((skillArray, i) => {
      let userScore = state.scoring[i]
      skillArray.forEach(skill => {
        scoreArray.forEach(score => {
          if (score.name === skill) {
            score.score += userScore
          }
        })
      })
    })

    // calculate percentage
    scoreArray.forEach(score => {
      score.percent = (score.score / score.highest) * 100
    })

    // calculate average of percentages
    let average = 0
    scoreArray.forEach(score => {
      average += score.percent
    })

    // Store the score array for us on the scenario resource page
    actions.setFoundScenario(prevState => ({
      ...prevState,
      completedSkillsScore: scoreArray,
    }))

    const percentage = average / scoreArray.length

    // calculate which ending to send to
    // And then find the correct audio clip to display
    if (percentage < state.extraContent.minScoreGoodEnding) {
      setEndingContent(badEnding)
      setScenarioAudioOnEnd(okEndingScenarioAudio)
      setAudioOnEnd(state.finalOutcomes.okEndingAudio)
      setFeedbackContent(state.finalOutcomes.okOutcome)
    } else if (percentage < state.extraContent.minScoreBestEnding) {
      setEndingContent(goodEnding)
      setScenarioAudioOnEnd(goodEndingScenarioAudio)
      setAudioOnEnd(state.finalOutcomes.goodEndingAudio)
      setFeedbackContent(state.finalOutcomes.goodOutcome)
    } else {
      setEndingContent(bestEnding)
      setScenarioAudioOnEnd(bestEndingScenarioAudio)
      setAudioOnEnd(state.finalOutcomes.bestEndingAudio)
      setFeedbackContent(state.finalOutcomes.bestOutcome)
    }

    // track the score
    Completion(
      percentage,
      scoreArray,
      currentQuestion.fields.id.substring(0, 2)
    )

    // show full progress bar
    actions.setProgression(longestPath)
    // allow navigation away
    actions.setOnEnding(true)
    // show the ending
    setShowEnding(true)

    actions.setFoundScenario(prevState => ({
      ...prevState,
      currentOnEnding: true,
    }))

    // scroll up
    window.scrollTo(0, 0)
  }, [
    state.skills,
    state.extraContent.minScoreGoodEnding,
    state.extraContent.minScoreBestEnding,
    state.maxScore,
    state.scoring,
    state.finalOutcomes.bestOutcome,
    state.finalOutcomes.goodOutcome,
    state.finalOutcomes.okOutcome,
    actions,
    longestPath,
    badEnding,
    goodEnding,
    bestEnding,
    currentQuestion,
  ])

  // go to next question
  const nextQuestion = () => {
    if (!selectedAnswer) {
      setNextError(true)
    } else if (selectedAnswer.fields.sendToEnd === true) {
      // save score, send to end
      saveScore(true)
    } else if (selectedAnswer.fields.nextQuestion) {
      // save score, don't send to end
      saveScore(false)
      setCurrentQuestion({})

      // find the next question based on the answer (not linear)
      questions.forEach(question => {
        if (
          question.fields.id === selectedAnswer.fields.nextQuestion.fields.id
        ) {
          setCurrentQuestion(question)
          setSelectedAnswer(false)
          actions.setProgression(state.progression + 1)
          window.scrollTo(0, 0)
        }
      })
    } else {
      setErrorState(true)
      window.scrollTo(0, 0)
    }
  }

  // shuffle array
  const shuffle = array => {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }

  const onCompleteButtonClicked = () => {
    // This run is done, save it to use to compare to future runs
    actions.setFoundScenario(prevState => ({
      ...prevState,
      completedSkills: state.skills,
      completedMaxScore: state.maxScore,
      completedScoring: state.scoring,
      currentQuestion: null,
      currentSkills: null,
      currentMaxScore: null,
      currentScoring: null,
      currentProgression: 0,
      currentOnEnding: false,
    }))

    // reset scoring & progression
    actions.setSkills([])
    actions.setMaxScore([])
    actions.setScoring([])
    actions.setProgression(1)
    actions.clearLastVisitedScenario()
    actions.clearCompletedQuestions()
    history.push(`/resources/${paramRole}/${paramConcern}`)
  }

  // shuffle the current responses
  useEffect(() => {
    if (currentQuestion.fields && currentQuestion.fields.answers) {
      setShuffledResponses(shuffle(currentQuestion.fields.answers))
    }
  }, [currentQuestion])

  // calculate score if send to end is true
  useEffect(() => {
    if (sendToEnd && !showEnding) {
      calculateScore()
    }
  }, [calculateScore, sendToEnd, showEnding])

  // Find the next question title to display in CTA 
  const currentQuestionIndex = questions.findIndex((question => question.fields.id === state.foundScenario.currentQuestion))
  let currentQuestionAnswers = questions[currentQuestionIndex]
  if (!currentQuestionAnswers) {
    return null
  }
  let answersArray;
  let nextQuestionTitle;
  if (currentQuestionAnswers) {
    answersArray = currentQuestionAnswers.fields.answers

    answersArray.map((answer) => {
      if (selectedAnswer === answer && answer.fields.sendToEnd === true) {
        nextQuestionTitle = 'See your results'
      } else if (selectedAnswer === answer) {
        nextQuestionTitle = answer.fields.nextQuestion.fields.title
      }
    })
  }
  

  return (
    <div className="question-component">
      {currentQuestion.fields && !errorState && !showEnding && (
        <TransitionGroup>
          <CSSTransition
            key={currentQuestion.fields.id}
            classNames="fade"
            timeout={500}
          >
            <div>
              <div className="gradient-wrapper">
                <div className="container">
                  {scenarioHeader && <h1>{currentQuestion.fields.title}</h1>}

                  {currentQuestion && currentQuestion.fields.audio && (
                    <AudioSample audioClip={currentQuestion.fields.audio} text="Listen to text" />
                  )}

                  {currentQuestion.fields.image && (
                      <Illustration 
                        url={currentQuestion.fields.image.fields.file.url} 
                        alt={currentQuestion.fields.image.fields.description}
                        className="question-image"
                      />
                    )}

                  <div className="content-container">
                    {currentQuestion.fields.question && (
                      <div className="">
                        {documentToReactComponents(
                          currentQuestion.fields.question
                        )}
                      </div>
                    )}
                  </div>
                  

                  {currentQuestion.fields.answerIntro && (
                    <p className="answer-intro">
                      {currentQuestion.fields.answerIntro}
                    </p>
                  )}
                  {selectResponseText && (
                    <p className="instruction">{selectResponseText}</p>
                  )}
                </div>
              </div>

              <div className="responses">
                <div className="container">
                  <div className="responses-wrapper">
                    {shuffledResponses &&
                      shuffledResponses.map((answer, i) => (
                        <div
                          key={`answer-${i}`}
                          onClick={() => selectAnswer(answer)}
                          onKeyPress={() => selectAnswer(answer)}
                          role="button"
                          className={
                            selectedAnswer === answer
                              ? 'response active'
                              : 'response'
                          }
                          data-response={`${currentQuestion.fields.id} - ${answer.fields.score}`}
                          tabIndex={0}
                        >
                          <div className='response-checkmark'>
                            <svg className='checkmark-svg' width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g id="div.step-number">
                              <rect width="30" height="30" rx="15" fill="white"/>
                              <path id="Vector 2" d="M9 15.0909L13.5882 20L22 11" stroke="black" strokeWidth="2"/>
                            </g>
                            </svg>
                          </div>
                          <p className='text answer'>{answer.fields.answer}</p>
                          <div className="answer-audio">
                            {answer.fields.audioSample && (
                               <AudioSample audioClip={answer.fields.audioSample} text="Listen to text" />
                            )}
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="next-button">
                    <button
                      onClick={nextQuestion}
                      className={
                        selectedAnswer
                          ? 'primary has-arrow'
                          : 'outline has-arrow'
                      }
                    >
                        {selectedAnswer ? nextQuestionTitle : 'Choose a response above'}
                      
                      <div className="arrow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.5"
                          height="16"
                        >
                          <path d="M17.2 7.3L9.7.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4L14 7H1c-.6 0-1 .4-1 1s.4 1 1 1h13l-5.6 5.3c-.4.4-.4 1 0 1.4.1.2.3.3.6.3.2 0 .5-.1.7-.3l7.5-7c.2-.2.3-.5.3-.7s-.1-.5-.3-.7z" />
                        </svg>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
      )}

      <CSSTransition
        in={showEnding}
        timeout={500}
        classNames="fade"
        unmountOnExit
        appear
      >
        <>
          <div className="gradient-wrapper">
            <div className="container ending">
              <div className="final-outcome-container">
                <h1>Final Outcome</h1>
                
                {state.finalOutcomes && (
                  <AudioSample audioClip ={scenarioAudioOnEnd} text="Listen to text" />
                )}
                
                <div className="ending-content">{documentToReactComponents(endingContent)}</div>
                
                <div className="learn-more-container">
                  <div className="learn-more">
                    <p>Learn More</p>
                    
                    <div className="arrow-container">
                      <svg className="darkblue-arrow-down"  width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.2 7.3L9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3C7.9 0.7 7.9 1.3 8.3 1.7L14 7H1C0.4 7 0 7.4 0 8C0 8.6 0.4 9 1 9H14L8.4 14.3C8 14.7 8 15.3 8.4 15.7C8.5 15.9 8.7 16 9 16C9.2 16 9.5 15.9 9.7 15.7L17.2 8.7C17.4 8.5 17.5 8.2 17.5 8C17.5 7.8 17.4 7.5 17.2 7.3Z" fill="#141E29"/>
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="feedback-content">
                  {state.finalOutcomes && (
                    <AudioSample audioClip ={audioOnEnd} text="Listen to text" />
                  )}

                  <div className="ending-content">{documentToReactComponents(feedbackContent)}</div>
                </div>

                <div className="button-wrapper final-outcome">
                  <button
                    onClick={onCompleteButtonClicked}
                    className="button primary has-arrow"
                  >
                    See your results
                    <div className="arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.5"
                        height="16"
                      >
                        <path d="M17.2 7.3L9.7.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4L14 7H1c-.6 0-1 .4-1 1s.4 1 1 1h13l-5.6 5.3c-.4.4-.4 1 0 1.4.1.2.3.3.6.3.2 0 .5-.1.7-.3l7.5-7c.2-.2.3-.5.3-.7s-.1-.5-.3-.7z" />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
              <div className="content-container">
                <Illustration 
                  url={state.finalOutcomes.finalOutcomeImage.fields.file.url} 
                  alt={state.finalOutcomes.finalOutcomeImage.fields.description}
                  id="final-outcome-image"
                />

                <div className="final-outcome-natural-supports-description">
                  <div className="description-header">
                    <h2><b>What is a Natural Support</b></h2>
                  </div>

                  <div className="description-text">
                    {state.finalOutcomes && (
                      <div className="natural-supports-audio">
                        <AudioSample audioClip={state.finalOutcomes.naturalSupportsDescriptionAudio} text="Listen to text" />
                      </div>
                    )}

                    {documentToReactComponents(
                      state.finalOutcomes.naturalSupportsDescription
                    )}
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </>
      </CSSTransition>

      {errorState && (
        <div className="container">
          <p>
            Sorry something went wrong. Your answer either doesn't lead to
            another question, or to the end of the simulation.
          </p>
        </div>
      )}
    </div>
  )
}

export default Question
