import React from 'react'
import { NavLink } from 'react-router-dom'
import getUrl from '../../../selectors/get-url'
import isExternal from '../../../selectors/link/is-external'

const Link = ({
  url: rawUrl = '',
  label = '',
  className = 'link',
  onClick,
  children,
}) => {
  const external = isExternal(rawUrl)

  if (external) {
    return (
      <a
        className={className}
        href={rawUrl}
        rel="noreferrer noopener"
        target="_blank"
        onClick={onClick}
      >
        {children}
        {label}
      </a>
    )
  }

  const url = getUrl(rawUrl)

  return (
    <NavLink className={className} to={url} onClick={onClick}>
      {children}
      {label}
    </NavLink>
  )
}

export default Link
