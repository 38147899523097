import React, { useRef } from 'react'

const WatermarkBackground = ({ children, scrollY }) => {
  const element = useRef(null)
  const topOffset = element.current ? element.current.offsetTop : 0
  const offset = (-scrollY + topOffset) / 3
  const backgroundPosition =
    scrollY > topOffset ? `left bottom ${offset}px` : 'left bottom 0'
  const style = { backgroundPosition }

  return (
    <div ref={element} style={style} className="watermark-background">
      {children}
    </div>
  )
}

export default WatermarkBackground
