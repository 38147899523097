import React, { useState, useContext } from 'react'
import { GlobalContext } from '../context/GlobalContext'
import Modal from 'react-modal'

function ContinueModal({ history, isOpen = false }) {
  const [modalOpen, setModalOpen] = useState(isOpen)

  const { actions } = useContext(GlobalContext)

  const closeModal = () => {
    setModalOpen(false)
  }

  const continueSimulation = () => {
    // Move to simulation page
    history.push('/simulation')
  }
  const startOver = () => {
    // Wipe out "currentRun" data and close modal
    actions.setFoundScenario({})
    actions.setLocalStorageScenarios([])

    actions.setOnEnding(false) 
    actions.setUserRole('')
    actions.setUserConcern('')
    actions.setSkills([])
    actions.setMaxScore(null)
    actions.setScoring(null)
    actions.setProgression(0)
    actions.clearCompletedQuestions()
    actions.nuclearResetScenario()
    closeModal()
  }

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      contentLabel="Interstitial Modal"
      className="modal interstitial-modal"
      overlayClassName="modal-overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="modal-body">
        <p className="interstitial-header">Continue</p>
        <h1>Would you like to pick up from where you left off?</h1>
        <p>
          It looks like you've made some progress with this scenario in the
          past. You can either continue from the last question you answered, or
          start from the beginning.
        </p>
        <button onClick={continueSimulation} className="primary">
          Continue
        </button>
        <button
          onClick={startOver}
          className="primary"
          style={{ marginLeft: '1rem' }}
        >
          Start over
        </button>
      </div>
    </Modal>
  )
}

export default ContinueModal
