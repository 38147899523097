import React from 'react'
import RichText from '../../RichText'

const TitleBlock = ({ content }) => (
  <div className="title-block content-block">
    <RichText content={content} />
  </div>
)

export default TitleBlock
