const AudioEvent = (progress, audioFileReference) => {
  const dataLayer = window.dataLayer || [];
  let event_type;
  switch (progress) {
    case '0':
      event_type = 'audio_start';
      break;
    case '100':
      event_type = 'audio_complete';
      break;
    case '25':
    case '50':
    case '75':
      event_type = 'audio_progress';
      break;
  }

  if (dataLayer && event_type) {
    dataLayer.push({
      event: event_type,
      title: audioFileReference.title,
      file_name: audioFileReference.file.fileName,
      url: audioFileReference.file.url,
      progress: progress,
    })
  }
}

export default AudioEvent
