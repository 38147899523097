import React, { useEffect } from 'react'

const SocialBlock = ({ headline, textAboveButtons, socialShareTitle }) => {
  useEffect(() => {
    // this will trigger add2any to activate once the component is rendered
    if (window.a2a) {
      window.a2a.init_all()
    }
  }, []);

  return (
    <div className="social-block">
      <h2>{headline}</h2>

      <div className="social-wrapper">
        {textAboveButtons && <h3>{textAboveButtons}</h3>}
        {/*<!-- AddToAny BEGIN --> */}
        <div
          className="a2a_kit a2a_kit_size_32 a2a_default_style"
          data-a2a-url="https://www.naturalsupportssimulation.com/"
          data-a2a-title={socialShareTitle}
        >
          {/* <a className="a2a_dd" href="https://www.addtoany.com/share"></a> */}
          <a className="a2a_button_x"></a>
          <a className="a2a_button_whatsapp"></a>
          <a className="a2a_button_facebook"></a>
          <a className="a2a_button_email"></a>
          <a className="a2a_button_linkedin"></a>
        </div>
        {/*<!-- AddToAny END -->*/}
      </div>
    </div>
  )
}

export default SocialBlock
