import React, { useState, useContext } from 'react'
import { GlobalContext } from '../context/GlobalContext'
import Modal from 'react-modal'
import MapPin from '../images/map-pin-fill.svg'

Modal.setAppElement('#root')

const LocationChooser = ({ history, open = false}) => {
  const [isOpen, setIsOpen] = useState(open)
  const [selectedLocation, setSelectedLocation] = useState()
  const closeModal = () => {
    setIsOpen(false)
  }
  const {
    actions: { setUserAreaServed },
    state: { chooseLocation, userAreaServed }
  } = useContext(GlobalContext)

  const groupedLocations = chooseLocation.reduce((memo, location) => {
    if (!memo.hasOwnProperty(location.province)) {
      memo[location.province] = []
    }
    memo[location.province] = [...memo[location.province], location]
    return memo
  }, {})
  const alphabetizedProvinces = Object.keys(groupedLocations).sort()

  const toggleModal = (event) => {
    event.preventDefault()
    setIsOpen(true)
  }

  const handleChange = (event) => {
    setSelectedLocation(event.target.value)
  }

  const handleSubmit = (_) => {
    setUserAreaServed(selectedLocation)
    setIsOpen(false)
  }

  return (
    <div className="location-chooser-modal">
      <button className="launcher" onClick={toggleModal} >
        Location<br />
        {userAreaServed}
      </button>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Interstitial Location Modal"
        className="modal locations-modal interstitial-modal"
        overlayClassName="modal-overlay locations-modal"
        shouldCloseOnOverlayClick={true}
      >
        <div className="modal-body locations-modal">
          <form>
            <h2 className="locations-modal-header">Location</h2>
            <h3>Your location is set to <img className='map-pin-icon' src={MapPin} alt="Map Pin" aria-hidden="true" /> {userAreaServed}</h3>
            <p className="header-location-text">
              To change the location, select the closest major city from the list
            </p>
            <p>
              <select defaultValue={userAreaServed} onChange={handleChange}>
                {alphabetizedProvinces.map((province) => {
                  return(
                    <optgroup label={province} key={province}>
                      {groupedLocations[province].map((location) => {
                        return(
                          <option key={location.areaServed}>
                            {location.areaServed}
                          </option>
                        )
                      })}
                    </optgroup>
                  )
                })}
              </select>
            </p>
            <button
              className="set-location-button"
              onClick={handleSubmit}
            >
              Set Location

              <span className="darkblue-right-arrow">
                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="location-arrow" d="M17.2 7.3L9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3C7.9 0.7 7.9 1.3 8.3 1.7L14 7H1C0.4 7 0 7.4 0 8C0 8.6 0.4 9 1 9H14L8.4 14.3C8 14.7 8 15.3 8.4 15.7C8.5 15.9 8.7 16 9 16C9.2 16 9.5 15.9 9.7 15.7L17.2 8.7C17.4 8.5 17.5 8.2 17.5 8C17.5 7.8 17.4 7.5 17.2 7.3Z" fill="#141E29"/>
                </svg>
              </span>
            </button>
          </form>
        </div>
      </Modal>
    </div>
  )
}

export default LocationChooser
