import React from 'react'
import getImageEntryBackgroundStyle from '../../../selectors/entry/get-image-entry-background-style'
import useWindowScrollPosition from '@rehooks/window-scroll-position'

const DesktopBackground = ({ isHomePage, desktopBackground, children }) => {
  const { y: scrollY } = useWindowScrollPosition({ throttle: 200 })
  const backgroundPositionY = isHomePage ? scrollY / 4 : 0
  const desktopBackgroundStyle = getImageEntryBackgroundStyle(
    desktopBackground,
    { backgroundPositionY }
  )

  return (
    <div className="hero-desktop-background" style={desktopBackgroundStyle}>
      {children}
    </div>
  )
}

export default DesktopBackground
