import React from 'react'
import RichText from '../../RichText'
import Link from '../link/Link'
import Step from './Step'
import SmallGear from '../../../images/small-gear.svg'
import Gear from './Gear'
import WatermarkBackground from './WatermarkBackground'
import useWindowScrollPosition from '@rehooks/window-scroll-position'

const StepsBlock = ({ content, button: buttonEntry, steps = [] }) => {
  const button = buttonEntry
  const { y: scrollY } = useWindowScrollPosition({ throttle: 200 })
  return (
    <WatermarkBackground scrollY={scrollY}>
      <div className="steps-block">
        <Gear scrollY={scrollY} />
        <div className="steps-content">
          <div className="steps-block-left">
            <div className="small-gear">
              <img src={SmallGear} alt="Small Gear" aria-hidden="true" />
            </div>
            <RichText content={content} />
            <Link className="button primary thick-border" {...button} />
          </div>
          <div className="steps-block-right">
            <div>
              {steps.map((step, index) => (
                <Step key={step.id} number={index + 1} {...step} />
              ))}
            </div>
            <Link className="button primary thick-border" {...button} />
          </div>
        </div>
      </div>
    </WatermarkBackground>
  )
}

export default StepsBlock
