import React from 'react'
import RichText from '../../RichText'
import Resource from './Resource'

const ResourcesBlock = ({
  content,
  resources = [],
  disclaimer = '',
}) => {
  const resourcesLeft = resources.slice(0, 1)
  const resourcesRight = resources.slice(1, 3)
  return (
    <div className="resources-block content-block">
      <RichText content={content} />
      <div className="resources">
        <div className="resources-left">
          {resourcesLeft.map((resource) => (
            <Resource key={resource.id} {...resource} />
          ))}
        </div>
        <div className="resources-right">
          {resourcesRight.map((resource) => (
            <Resource key={resource.id} {...resource} />
          ))}
        </div>
      </div>
      {disclaimer && <p className="disclaimer">{disclaimer}</p>}
    </div>
  )
}

export default ResourcesBlock
