import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { Link } from 'react-router-dom'

const handleLinkOption = (node, children) => {
  const uri = node.data.uri
  if (uri.slice(0, 1) === '/') {
    return <Link to={`${uri}`}>{children}</Link>
  }
  return (
    <a target="_blank" rel="noreferrer noopener" href={uri}>{children}</a>
  )
}

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    [INLINES.HYPERLINK]: handleLinkOption,
  },
  renderText: text =>
    text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
}

const RichText = ({ content }) => (
  <div className="rich-text">{documentToReactComponents(content, options)}</div>
)

export default RichText
