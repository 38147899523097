import React from 'react'
import SkillsListBlockSkill from './SkillsListBlockSkill'

const SkillsListBlock = ({ skills }) => {
  return (
    <div className="skills-list-block">
      <div className="skills-list-block-skills">
        {skills.map(skill => (
          <SkillsListBlockSkill key={skill.id} {...skill} />
        ))}
      </div>
    </div>
  )
}
export default SkillsListBlock
