const SimulationExit = (url, question) => {
  console.log(`Simulation Exit: ${url} on ${question}`)
  const dataLayer = window.dataLayer || []
  if (dataLayer) {
    dataLayer.push({
      event: 'exit',
      category: 'exit',
      action: url,
      label: question,
    })
  }
}

export default SimulationExit
