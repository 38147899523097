import React, { useContext } from 'react'
import { GlobalContext } from '../context/GlobalContext'
import getFlattenedEntries from '../selectors/entry/get-flattened-entries'
import Link from '../components/content-blocks/link/Link'
import Image from '../components/Image'
import RichText from '../components/RichText'

const Footer = () => {
  const { state } = useContext(GlobalContext)
  const { headerFooter } = state
  const {
    footerLinks = [],
    socialImpactLogo = {},
    socialImpactUrl = '',
    unitedWayLogo = {},
    unitedWayUrl = '',
    unitedWayContent,
    backToTopText = '',
  } = headerFooter

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop)
      window.scrollTo(0, c - c / 12)
    }
  }

  return (
    <div className="footer-wrapper">
      {backToTopText && (
        <button onClick={() => scrollToTop()} className="back-to-top">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.4 19">
            <path
              d="M.3 10c.4.4 1 .4 1.4 0l5.9-6.4V18c0 .6.4 1 1 1s1-.4 1-1V3.5l5.9 6.4c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4L9.4.3C9.2.1 9 0 8.7 0s-.6.1-.8.3L.3 8.5c-.4.4-.4 1.1 0 1.5z"
              fill="#131e29"
            />
          </svg>
          {backToTopText}
        </button>
      )}
      <footer>
        <div className="footer-links">
          <div className="footer-links-content">
            {getFlattenedEntries(footerLinks).map(({ key, fields }) => (
              <Link key={key} {...fields} />
            ))}
          </div>
        </div>
        <div className="footer-social-impact">
          <Link url={socialImpactUrl}>
            <Image imageEntry={socialImpactLogo} />
          </Link>
        </div>
        <div className="footer-united-way">
          <Link url={unitedWayUrl}>
            <Image imageEntry={unitedWayLogo} />
          </Link>
          <RichText content={unitedWayContent} />
        </div>
      </footer>
    </div>
  )
}

export default Footer
