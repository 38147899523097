import React, { useEffect, useState, useContext } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import { GlobalContext } from '../context/GlobalContext'
import slugify from 'slugify'
import _ from 'lodash'
import getFlattenedEntries from '../selectors/entry/get-flattened-entries'

import MetaTags from '../components/MetaTags'
import Surveys from '../components/Surveys'
import TitleBlock from '../components/content-blocks/title-block/TitleBlock'
import ResourceGrid from '../components/ResourceGrid'
import ResourcesBlock from '../components/content-blocks/resources-block/ResourcesBlock'

import iconSurvey from '../images/survey.svg'

function ScenarioResources({ history }) {
  const [foundScenario, setFoundScenario] = useState({})
  const [globalResources, setGlobalResources] = useState([])
  const [scenarioResources, setScenarioResources] = useState([])
  const [titleContent, setTitleContent] = useState({})
  const [skills, setSkills] = useState([])
  const [skillsToDevelop, setSkillsToDevelop] = useState([])
  const [resourcesContent, setResourcesContent] = useState({})
  const [globalResourcesShuffled, setGlobalResourcesShuffled] = useState(false)
  const [
    additionalResourcesPreamble,
    setAdditionalResourcesPreamble,
  ] = useState('')

  const { state, actions } = useContext(GlobalContext)

  // Check if URL params are being sent to load the simulation directly
  const { paramRole, paramConcern } = useParams()

  useEffect(() => {
    actions.setIsSimulation(false)
    actions.setHideFooter(false)
  }, [actions])

  // Use contentful and pull specific content blocks
  useEffect(() => {
    // Check if this scenario exists before loading page, if it doesn't forward user to global resources page
    state.contentfulClient
      .getEntries({ content_type: 'scenario', include: 2 })
      .then(entries => {
        const foundScenario = entries.items.find(
          scenario =>
            slugify(scenario.fields.role.fields.role.toLowerCase().trim()) ===
              paramRole &&
            slugify(
              scenario.fields.concern.fields.name.toLowerCase().trim()
            ) === paramConcern
        )

        if (!foundScenario) {
          history.push('/resources')
        } else {
          setFoundScenario(foundScenario)
        }
      })

    // Resource Page - Title Block Entry
    state.contentfulClient.getEntry('28N0oWiZogs6160tGOXxVt').then(entry => {
      setTitleContent(entry.fields)
    })

    // Get all skills
    state.contentfulClient
      .getEntries({ content_type: 'skillsBlockSkill' })
      .then(response => {
        setSkills(getFlattenedEntries(response.items))
      })
      .catch(err => console.log(err))

    // Get all Global Resources
    state.contentfulClient
      .getEntries({ content_type: 'globalResource' })
      .then(response => {
        setGlobalResources(getFlattenedEntries(response.items))
      })
      .catch(err => console.log(err))

    const resource_page = state.pages.get('resources')
    const resource_block = resource_page.contentBlocks.find(contentBlock =>
      contentBlock.type === 'resourcesBlock'
    )
    setResourcesContent(resource_block)

    // Check is user has completed the simulation for this scenario
    if (paramRole !== '' && paramConcern !== '') {
      let scenario = state.localStorageScenarios.find(
        storedScenario =>
          paramRole === slugify(storedScenario.role) &&
          paramConcern === slugify(storedScenario.concern)
      )

      if (!_.isEmpty(scenario)) {
        setSkillsToDevelop(
          scenario.completedSkillsScore.filter(skillScore => {
            return skillScore.percent < 70 ? true : false
          })
        )
      }
    }
  }, [
    paramConcern,
    paramRole,
    history,
    state.contentfulClient,
    state.localStorageScenarios,
    state.pages
  ])

  useEffect(() => {
    if (
      !_.isEmpty(foundScenario) &&
      !_.isEmpty(foundScenario.fields.resources)
    ) {
      setScenarioResources(getFlattenedEntries(foundScenario.fields.resources))
      setAdditionalResourcesPreamble(foundScenario.fields.resourcesPreamble)
    }
  }, [foundScenario])

  // User hasn't completed the simulation or has no skills to work on, shuffle all global resources
  useEffect(() => {
    if (
      skillsToDevelop.length === 0 &&
      globalResources.length > 0 &&
      !globalResourcesShuffled
    ) {
      setGlobalResources(_.shuffle(globalResources))
      setGlobalResourcesShuffled(true)
    }
  }, [skillsToDevelop, globalResources, globalResourcesShuffled])

  return (
    <>
      <MetaTags
        metaTitle={'Natural Supports | Resources'}
        metaDescription={
          'To build trusting relationships and support children and youth who may be experiencing a mental health concern, adults need to practice and apply 8 supportive skills.'
        }
      />
      <div className={'page scenario-resources-page dark top-padding'}>
        <div className="page-content">
          <div className="block-wrapper">
            <div className="page-title-wrapper">
              <h1 className="page-title">RESOURCES AND SUPPORT</h1>
            </div>
          </div>
          <div className="content-blocks">
            {!_.isEmpty(titleContent) && (
              <div className="block-wrapper titleBlock-wrapper">
                <div className="title-block content-block">
                  <div className="rich-text">
                    <h1>{titleContent.title}</h1>
                  </div>
                </div>
              </div>
            )}

            {!_.isEmpty(titleContent) && (
              <div className="block-wrapper titleBlock-wrapper">
                <TitleBlock {...titleContent} />
              </div>
            )}

            <div className="block-wrapper developSkillsBlock-wrapper">
              <div className="developSkills-block content-block">
                {skillsToDevelop.length > 0 && (
                  <>
                    <h2>Develop these skills:</h2>
                    <ul className="skillList">
                      {skills
                        .filter(skill => {
                          return skillsToDevelop.find(
                            skillToDevelop =>
                              skill.preTitle === skillToDevelop.name
                          )
                        })
                        .sort((a, b) => {
                          if (a.number < b.number) {
                            return -1
                          } else if (a.number === b.number) {
                            return 0
                          } else {
                            return 1
                          }
                        })
                        .map(skill => {
                          return (
                            <li key={`skill-${skill.number}`}>
                              Skill{' '}
                              <span
                                className={`skillNumber skill-${skill.number}`}
                              >
                                {skill.number}
                              </span>
                              : {skill.title}
                            </li>
                          )
                        })}
                    </ul>
                  </>
                )}

                {globalResources && (
                  <ResourceGrid
                    resources={globalResources.filter(resource => {
                      // No skills to develop supplied, return all resources
                      if (_.isEmpty(skillsToDevelop)) {
                        return true
                      }

                      // Check if resource's skills are in the skills to develop array
                      return getFlattenedEntries(resource.skills).some(
                        ({ preTitle }) => {
                          let skillFound = false
                          skillsToDevelop.forEach(skillToDevelop => {
                            if (preTitle === skillToDevelop.name) {
                              skillFound = true
                            }
                          })

                          return skillFound
                        }
                      )
                    })}
                    skillsToDevelop={skillsToDevelop}
                    hideSkillNumbers={globalResourcesShuffled ? true : false}
                  />
                )}
              </div>
            </div>

            {!_.isEmpty(scenarioResources) && (
              <div className="block-wrapper additionalResourcesBlock-wrapper">
                <div className="additionalResources-block content-block">
                  <h2>Addtional Resources</h2>
                  <p>{additionalResourcesPreamble}</p>

                  <ResourceGrid
                    resources={scenarioResources}
                    initialSize={3}
                    sizeStep={4}
                  />
                </div>
              </div>
            )}

            {!_.isEmpty(resourcesContent) && (
              <div className="block-wrapper resourcesBlock-wrapper">
                <ResourcesBlock {...resourcesContent} />
              </div>
            )}

            <div className="block-wrapper takeSurveyBlock-wrapper">
              <div className="takeSurvey-block">
                <img src={iconSurvey} alt="" />
                <h2>
                  Help us improve your experience, please fill out a quick
                  questionnaire.
                </h2>
                <button
                  onClick={e => {
                    e.preventDefault()
                    actions.setShowCompletionSurvey(true)
                  }}
                  className="outline"
                >
                  Start now
                </button>
              </div>
            </div>
          </div>
        </div>
        <Surveys />
      </div>
    </>
  )
}

export default withRouter(ScenarioResources)
