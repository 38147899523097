import React, { useState, useContext } from 'react'
import { GlobalContext } from '../context/GlobalContext'

const FullPageLocationChooser = () => {
  const [selectedLocation, setSelectedLocation] = useState()
  const {
    actions: { setUserAreaServed },
    state: { chooseLocation, userAreaServed, hasPagesLoaded }
  } = useContext(GlobalContext)

  const groupedLocations = chooseLocation.reduce((memo, location) => {
    if (!memo.hasOwnProperty(location.province)) {
      memo[location.province] = []
    }
    memo[location.province] = [...memo[location.province], location]
    return memo
  }, {})
  const alphabetizedProvinces = Object.keys(groupedLocations).sort()

  const handleChange = (event) => {
    setSelectedLocation(event.target.value)
  }

  const handleSubmit = (_) => {
    setUserAreaServed(selectedLocation)
  }

  const hideEverything = userAreaServed && !hasPagesLoaded

  return (
    <div className='location-chooser-wrapper watermark-background'>
      <form className={`location-chooser rich-text ${hideEverything ? 'visually-hidden': ''}`}>
        <h1>Everyone has the ability to be a Natural Support</h1>
        <p>Explore your Natural Support skills and learn ways to assist youth in your life.</p>
        <h2>Select your local United Way</h2>
        <p className="small">Select the closest major city from the list</p>
        <p className="small">
          <select defaultValue={''} onChange={handleChange}>
            <option hidden value="">Enter Location</option>
            {alphabetizedProvinces.map((province) => {
              return(
                <optgroup label={province} key={province}>
                  {groupedLocations[province].map((location) => {
                    return(
                      <option key={location.areaServed}>
                        {location.areaServed}
                      </option>
                    )
                  })}
                </optgroup>
              )
            })}
          </select>
        </p>
        <button
          className="set-location-button"
          onClick={handleSubmit}
        >
          Set Location

          <span className="darkblue-right-arrow">
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="location-arrow" d="M17.2 7.3L9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3C7.9 0.7 7.9 1.3 8.3 1.7L14 7H1C0.4 7 0 7.4 0 8C0 8.6 0.4 9 1 9H14L8.4 14.3C8 14.7 8 15.3 8.4 15.7C8.5 15.9 8.7 16 9 16C9.2 16 9.5 15.9 9.7 15.7L17.2 8.7C17.4 8.5 17.5 8.2 17.5 8C17.5 7.8 17.4 7.5 17.2 7.3Z" fill="#141E29"/>
            </svg>
          </span>
        </button>
      </form>
    </div>
  )
}

export default FullPageLocationChooser
