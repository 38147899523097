import React, { useContext, useEffect } from 'react'
import { unslugify } from 'unslugify'
import slugify from 'slugify'

import { Helmet } from 'react-helmet'
import { GlobalContext } from '../context/GlobalContext'
import { withRouter } from 'react-router-dom'
import SimulationHeader from '../components/SimulationHeader'
import Illustration from '../components/Illustration'
import RichText from '../components/RichText'

function ChooseConcern({ history }) {
  const { state, actions } = useContext(GlobalContext)

  useEffect(() => {
    // controls which header/footer show up
    actions.setIsSimulation(false)
    actions.setHideFooter(true)
    actions.setShowBackButton(true)

    // clear previous concern choice
    actions.setUserConcern('')
  }, [actions])

  // redirect to beginning if role hasn't been picked
  useEffect(() => {
    if (state.userRole === '') {
      history.push('/choose-role')
    }
  }, [state, history])

  // user chooses a concern and it gets saved to context
  // push user to the simulation
  const chooseConcern = concern => {
    actions.setUserConcern(unslugify(concern))
    const roleSlug = slugify(state.userRole.toLowerCase().trim());
    const concernSlug = slugify(state.userConcern.toLowerCase().trim());
    history.push(
      `/simulation/${roleSlug}/${concernSlug}`
    )
  }

  return (
    <div>
      <SimulationHeader />

      <div className="choose-page concern">
        <Helmet>
          <title>Natural Supports | Choose Concern</title>
          <meta
            name="description"
            content="Natural Supports Simulator, choose a concern"
          />
        </Helmet>

        {state.chooseConcern.question && (
          <h1 className="heading concern-page">{state.chooseConcern.question}</h1>
        )}

        {/* need to remove this field - rb */}
        {state.chooseConcern.helpText && (
          <p className="instructions">{state.chooseConcern.helpText}</p>
        )}

        <div>
        <div className="choices youth">
          {state.chooseConcern.rolesOrConcerns &&
            state.chooseConcern.rolesOrConcerns.map((concern, i) => (
              
              <button
                key={i}
                onClick={() => chooseConcern(concern.fields.name)}
                className="choice youth"
              >
                <div className="text concern-page-container">
                  <div className="text concern-page">
                    <p className="name">{concern.fields.descriptionHeadline}</p>
                    <p className="description">{concern.fields.description}</p>

                    <div className="cta-block youth">
                      <div className="begin-story-block youth">
                        <div
                          className="begin-story-button youth"
                        >
                          Meet {concern.fields.name}
                        </div>
                        <span className="darkblue-right-arrow youth">
                            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path id="youth-arrow" d="M17.2 7.3L9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3C7.9 0.7 7.9 1.3 8.3 1.7L14 7H1C0.4 7 0 7.4 0 8C0 8.6 0.4 9 1 9H14L8.4 14.3C8 14.7 8 15.3 8.4 15.7C8.5 15.9 8.7 16 9 16C9.2 16 9.5 15.9 9.7 15.7L17.2 8.7C17.4 8.5 17.5 8.2 17.5 8C17.5 7.8 17.4 7.5 17.2 7.3Z" fill="#141E29"/>
                            </svg>
                          </span>
                      </div>
                    </div>
                  </div>
                  {concern.fields.image && (
                      <div className="concern-image">
                        <Illustration url={concern.fields.image.fields.file.url} alt={concern.fields.image.description} />
                      </div>
                    )}
                </div>
              </button>
            ))}
        </div>
        </div>

        <div className="logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="72.2" height="24.7">
            <path
              d="M.4 7c-.6-.8-.5-1.5.4-2.2l6-4.5c.8-.6 1.6-.5 2.2.3 1.8 2 3.5 3.8 5.4 5.5 1.8 1.6 3.8 3 6 4.1 2.2 1.1 4.5 2 7.1 2.6 2.6.6 5.4.9 8.6.9 3.2 0 6.1-.3 8.7-.8 2.6-.5 5-1.3 7.1-2.4 2.2-1.1 4.2-2.4 6-3.9 1.9-1.5 3.6-3.3 5.3-5.3.6-.7 1.3-.8 2.2-.3l5.9 3.9c.9.6 1 1.3.5 2.2-4.2 6-9.3 10.4-15.3 13.3-6 2.9-12.8 4.3-20.4 4.3-7.6 0-14.4-1.4-20.4-4.3C9.8 17.5 4.7 13.1.4 7z"
              fill="#ffa400"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ChooseConcern)
