import React from 'react'
import Router from '../Router'
import { GlobalProvider } from '../context/GlobalContext'
import { Helmet } from 'react-helmet'
import { BrowserRouter } from 'react-router-dom'
import TagManager from 'react-gtm-module'

const locationString = (localStorage.getItem('location') || "{}")
const locationAreaServed = JSON.parse(locationString).areaServed
const tagManagerArgs = {
  gtmId: 'GTM-THNXR2W', // new container
  // gtmId: 'GTM-PJLC2ML' // United Way GTM container
  // gtmId: 'GTM-NZNN928' // justine test container
  dataLayer: {
    userId: '001',
    userProject: 'project',
    location: locationAreaServed
  }
}

TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <GlobalProvider>
      <Helmet>
        <title>Natural Supports</title>
        <meta name="description" content="Helmet application" />
        <script async src="https://static.addtoany.com/menu/page.js" />
      </Helmet>
      <div className="App">
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </div>
    </GlobalProvider>
  )
}

export default App
