import React, { useContext, useEffect, useState, useRef } from 'react'
import { unslugify } from 'unslugify'

import { Helmet } from 'react-helmet'
import { GlobalContext } from '../context/GlobalContext'
import { withRouter } from 'react-router-dom'

import Illustration from '../components/Illustration'

function ChooseRole({ history }) {
  const { state, actions } = useContext(GlobalContext)

  const [consent, setConsent] = useState(false)
  const [showError, setShowError] = useState(false) // Leaving error code for whenever design says to move forward with it.
  const [showRoleError, setShowRoleError] = useState(false)
  const [userRoleSelection, setUserRoleSelection] = useState('')

  useEffect(() => {
    // controls which header/footer show up
    actions.setIsSimulation(false)
    actions.setHideFooter(true)
    actions.setShowBackButton(false)

    // clears existing user role/concern
    actions.setUserRole('')
  }, [actions])

  // if a role and consent is selected then push user to choose concern page
  let beginStoryButton = useRef();
  const chooseRole = role => {
    if (consent && userRoleSelection) {
      actions.setUserRole(unslugify(role))
      history.push('/choose-concern')
    } else {
      beginStoryButton.current.classList.add('outline')
    }
  }

   // handle user selection
   const handleSelection = role => {
    setUserRoleSelection(role)
  }

  // user selects a role and it gets saved to state
  useEffect(() => {
    if (userRoleSelection) {
      actions.setUserRole(userRoleSelection)
    }
  }, [userRoleSelection, actions])


  // hide error if consent is confirmed
  useEffect(() => {
    if (consent) {
      setShowError(false)
    }
    if (userRoleSelection) {
      setShowRoleError(false)
    }
  }, [consent, userRoleSelection])


  return (
    <div className="choose-page">
      <Helmet>
        <title>Natural Supports | Choose Role</title>
        <meta
          name="description"
          content="Natural Supports Simulator, choose a role"
        />
      </Helmet>

      {state.chooseRole.question && (
        <h1 className="heading">{state.chooseRole.question}</h1>
      )}

      {state.chooseRole.helpText && (
        <p className="instructions">{state.chooseRole.helpText}</p>
      )}

      <div className="choices">
        {state.chooseRole.rolesOrConcerns &&
          state.chooseRole.rolesOrConcerns.map((role, i) => (         
            <button
              key={i}
              onClick={() => handleSelection(role.fields.role)}
              className={userRoleSelection === role.fields.role ? 'choice role-choice active' : 'choice role-choice'}
            >
              <div className="role-image">{role.fields.image && (<Illustration url={role.fields.image.fields.file.url} alt={role.fields.image.description} />)}</div>
                <div className="text">
                  <svg id="check-square" width="52" height="50" viewBox="0 0 52 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="bg-blue" d="M52 0L52 50L4 50C1.79086 50 5.74888e-07 48.2091 5.48545e-07 46L4.76995e-08 4C2.13558e-08 1.79086 1.79086 5.98738e-07 4 5.72394e-07L52 0Z" fill="#00CFE0"/>
                    <rect x="13" y="9.99997" width="30" height="30" rx="15" fill="white"/>
                    <path id="check-in-circle" d="M22 25.0909L26.5882 30L35 21" stroke="#B0B0B0"/>
                  </svg>
                  <p className="role-title">{role.fields.role}</p>
                </div>
            </button>
          ))}
      </div>

      <div className="consent">
        <div
          className={
            consent ? `checkbox-container active` : `checkbox-container`
          }
        >
          <input
            type="checkbox"
            name="consent"
            id="consent"
            value={consent}
            onChange={() => setConsent(!consent)}
          />
            {state.chooseRole.consentCheckboxLabel && (
              <label htmlFor="consent">
                {state.chooseRole.consentCheckboxLabel}
              </label>
            )}
        </div>

        <div className="cta-block">
          <div className="begin-story-block">
            {state.chooseRole.beginStoryCta && (
              <button
                ref={beginStoryButton}
                className={consent && userRoleSelection ? 'begin-story-button' : 'begin-story-button outline'}
                onClick={() => chooseRole(userRoleSelection)}
              >
                {consent && userRoleSelection ? state.chooseRole.beginStoryCta : 'Select above'}

                <span className="darkblue-right-arrow">
                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="consent-arrow" d="M17.2 7.3L9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3C7.9 0.7 7.9 1.3 8.3 1.7L14 7H1C0.4 7 0 7.4 0 8C0 8.6 0.4 9 1 9H14L8.4 14.3C8 14.7 8 15.3 8.4 15.7C8.5 15.9 8.7 16 9 16C9.2 16 9.5 15.9 9.7 15.7L17.2 8.7C17.4 8.5 17.5 8.2 17.5 8C17.5 7.8 17.4 7.5 17.2 7.3Z" fill="#141E29"/>
                  </svg>
                </span>
              </button>
            )}
          </div>

          <div className="logo">
            <svg xmlns="http://www.w3.org/2000/svg" width="72.2" height="24.7">
              <path
                d="M.4 7c-.6-.8-.5-1.5.4-2.2l6-4.5c.8-.6 1.6-.5 2.2.3 1.8 2 3.5 3.8 5.4 5.5 1.8 1.6 3.8 3 6 4.1 2.2 1.1 4.5 2 7.1 2.6 2.6.6 5.4.9 8.6.9 3.2 0 6.1-.3 8.7-.8 2.6-.5 5-1.3 7.1-2.4 2.2-1.1 4.2-2.4 6-3.9 1.9-1.5 3.6-3.3 5.3-5.3.6-.7 1.3-.8 2.2-.3l5.9 3.9c.9.6 1 1.3.5 2.2-4.2 6-9.3 10.4-15.3 13.3-6 2.9-12.8 4.3-20.4 4.3-7.6 0-14.4-1.4-20.4-4.3C9.8 17.5 4.7 13.1.4 7z"
                fill="#ffa400"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ChooseRole)
