import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import Header from './containers/Header'
import ScrollToTop from './components/ScrollToTop'
import ChooseRole from './containers/ChooseRole'
import ChooseConcern from './containers/ChooseConcern'
import Instructions from './containers/Instructions'
import Simulation from './containers/Simulation'
import ScenarioResources from './containers/ScenarioResources'
import Footer from './containers/Footer'
import Page404 from './containers/Page404'
import { GlobalContext } from './context/GlobalContext'
import Page from './containers/Page'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import getPageRoutes from './selectors/page/get-page-routes'
import FullPageLocationChooser from './components/FullPageLocationChooser'

function Router() {
  const { state } = useContext(GlobalContext)
  const { hasPagesLoaded } = state
  const pageRoutes = getPageRoutes(state)

  const hideEverything = !hasPagesLoaded

  return (
    <ScrollToTop>
      {!state.isSimulation && !hideEverything && <Header />}

      <main>
        <Route
          render={({ location }) => (
            <TransitionGroup>
              <CSSTransition key={location.key} classNames="fade" timeout={700}>
                <Switch>
                  {!hasPagesLoaded &&
                    <Route component={FullPageLocationChooser} path="/" />
                  }
                  <Route path="/instructions/" component={Instructions} />
                  <Route path="/choose-role/" component={ChooseRole} />
                  <Route path="/choose-concern/" component={ChooseConcern} />
                  <Route
                    path="/simulation/:paramRole?/:paramConcern?"
                    component={Simulation}
                  />
                  {pageRoutes.map((route, index) => (
                    <Route key={index} {...route} component={Page} />
                  ))}
                  <Route
                    path="/resources/:paramRole/:paramConcern"
                    component={ScenarioResources}
                  />
                  <Route component={Page404} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </main>

      {!state.isSimulation && !state.hideFooter && !hideEverything && <Footer />}
    </ScrollToTop>
  )
}

export default Router
