import React from 'react'

const Logo = () => (
  <div className="logo">
    <svg xmlns="http://www.w3.org/2000/svg" width="72.2" height="24.7">
      <path
        d="M.4 7c-.6-.8-.5-1.5.4-2.2l6-4.5c.8-.6 1.6-.5 2.2.3 1.8 2 3.5 3.8 5.4 5.5 1.8 1.6 3.8 3 6 4.1 2.2 1.1 4.5 2 7.1 2.6 2.6.6 5.4.9 8.6.9 3.2 0 6.1-.3 8.7-.8 2.6-.5 5-1.3 7.1-2.4 2.2-1.1 4.2-2.4 6-3.9 1.9-1.5 3.6-3.3 5.3-5.3.6-.7 1.3-.8 2.2-.3l5.9 3.9c.9.6 1 1.3.5 2.2-4.2 6-9.3 10.4-15.3 13.3-6 2.9-12.8 4.3-20.4 4.3-7.6 0-14.4-1.4-20.4-4.3C9.8 17.5 4.7 13.1.4 7z"
        fill="#ffa400"
      />
    </svg>
  </div>
)

export default Logo
