import React, { useContext, useEffect, useRef } from 'react'
import { GlobalContext } from '../context/GlobalContext'
import Dot from '../images/completed-question-dot.svg'
import BranchIcon from '../images/branch-icon.svg'

function ProgressBar({ currentScenario }) {
  const { state } = useContext(GlobalContext)
  const listElement = useRef();
  const foundScenario = state.foundScenario
  
  let longestPath = currentScenario.longestPath
  const pathPlusEnding = longestPath + 1
  let progress = Math.round((state.progression / pathPlusEnding) * 100)

  const currentQuestion = currentScenario.questions.find((question) => question.fields.id === foundScenario.currentQuestion)

  // Scroll to the end of the list of completed questions to show the current question in the progress bar on mobile view
  useEffect(() => {
    if (listElement.current) {
        if (window.innerWidth < 1024) {
          const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
              entry.target.scrollLeft = entry.target.scrollWidth
            }
          })
          observer.observe(listElement.current)
        } else {
          listElement.current.scrollTo(0, listElement.current.scrollHeight)
        }
    }
  }
  , [listElement.current, currentQuestion])



  return (
    <div className="progress-bar">
      <div className="container">
        <div className="progress-bar-left">
          <p>{currentScenario.scenarioHeader}</p>
        </div>
        <div className="progress-bar-right">
          <p>{progress}%</p>
          <span className="progress-bar-text">Complete</span>
        </div>
      </div>


      {state.completedQuestions && (
        <div className="completed-questions">
          <ul ref={listElement}>
            {state.completedQuestions.map((question, i) => {
              return (
                <li
                  key={`completed-question-${i}`}
                  className="completed-question"
                >
                  <img src={Dot} />
                  <p>{question}</p>
                </li>
              )
            })}

            {currentQuestion && (
                <li className="completed-question current">
                  <img src={BranchIcon} />
                  <p>{currentQuestion.fields.title}</p>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  )
}

export default ProgressBar
