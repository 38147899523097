import React, { useState, createContext, useEffect } from 'react'
import _ from 'lodash'

import getFlattenedEntry from '../selectors/entry/get-flattened-entry'
const contentful = require('contentful')

export const GlobalContext = createContext()

export const GlobalProvider = props => {
  // contentful client
  const [contentfulClient, setContentfulClient] = useState({})

  // contentful states
  const [scenarios, setScenarios] = useState([]) // scenario content from Contentful
  const [chooseRole, setChooseRole] = useState({}) // roles from Contentful
  const [chooseConcern, setChooseConcern] = useState({}) // concerns from Contentful
  const [chooseLocation, setChooseLocation] = useState([]) // locations from Contentful
  const [pages, setPages] = useState(new Map()) // pages content from Contentful
  const [hasPagesLoaded, setHasPagesLoaded] = useState(false)
  const [headerFooter, setHeaderFooter] = useState({}) // header/footer content from Contentful
  const [extraContent, setExtraContent] = useState({}) // extra content from Contentful
  const [finalOutcomes, setFinalOutcomes] = useState({}) // extra content from Contentful
  const [globalInterstitals, setGlobalInterstitials] = useState({}) // interstitial content from Contentful
  const [skillsContent, setSkillsContent] = useState({}) // skills content from Contenful

  // other states
  const savedSelectedLocation = JSON.parse(localStorage.getItem('location') || '{}')
  const [userRole, setUserRole] = useState('') // role that the user has chosen
  const [userConcern, setUserConcern] = useState('') // concern that the user has chosen
  const [userAreaServed, setUserAreaServed] = useState(
    (savedSelectedLocation && savedSelectedLocation.areaServed) || ''
  ) // name of location chosen by user
  const [userLocation, setUserLocation] = useState(savedSelectedLocation || {}) // location chosen by user
  const [isSimulation, setIsSimulation] = useState(false) // true when in the simulation (hides header & footer)
  const [hideFooter, setHideFooter] = useState(false) // explicit state to hide just the footer
  const [showCompletionSurvey, setShowCompletionSurvey] = useState(false) // true when the completion survey should appear on the results page
  const [showExitSurvey, setShowExitSurvey] = useState(false) // true when the completion survey should appear on the results page

  const [showBackButton, setShowBackButton] = useState(false) // show or hide back button in simulation header
  const [progression, setProgression] = useState(0) // which question is the user on?
  const [onEnding, setOnEnding] = useState(false) // state to track ending and allow navigation

  // scenarios in localStorage
  const [localStorageScenarios, setLocalStorageScenarios] = useState(
    JSON.parse(localStorage.getItem('scenarios')) || []
  )
  const [foundScenario, setFoundScenario] = useState({})

  // track user scoring
  const [skills, setSkills] = useState([])
  const [maxScore, setMaxScore] = useState([])
  const [scoring, setScoring] = useState([])

  const storedCompletedQuestions = localStorage.getItem('completedQuestions') || '[]'
  const [completedQuestions, setCompletedQuestions] = useState(JSON.parse(storedCompletedQuestions))

  const addCompletedQuestion = (newCompletedQuestion) => {
    setCompletedQuestions(prev => {
      const newValue = [...prev, newCompletedQuestion.fields.title]
      const newValueJson = JSON.stringify(newValue)
      localStorage.setItem('completedQuestions', newValueJson);
      return newValue;
    })
  }

  const clearCompletedQuestions = () => {
    setCompletedQuestions([])
    localStorage.setItem('completedQuestions', '[]');
  }

  const saveScenarios = scenarios => {
    localStorage.setItem('scenarios', JSON.stringify(scenarios))
  }
  const saveLocation = location => {
    localStorage.setItem('location', JSON.stringify(location))
  }
  const saveLastVisitedScenario = (role, concern) => {
    localStorage.setItem(
      'lastVisitedScenario',
      JSON.stringify({ role, concern })
    )
  }
  const loadLastVisitedScenario = () => {
    return JSON.parse(localStorage.getItem('lastVisitedScenario'))
  }
  const clearLastVisitedScenario = () => {
    localStorage.setItem('lastVisitedScenario', null)
  }

  const cleanContentfulRelations = (source) => {
    const isObject = (thing) =>
      typeof thing === "object" && thing !== null && !Array.isArray(thing);

    // object is relation when it looks like {sys: {}, fields: {}}
    const objectIsRelation = (obj) =>
      ["sys", "fields"].every(
        (k) => Object.keys(obj).includes(k) && isObject(obj[k])
      );

    if (Array.isArray(source)) {
      // given an array, return a cleaned array
      return source.map((x) =>
        Array.isArray(x) || (isObject(x) && objectIsRelation(x))
          ? cleanContentfulRelations(x)
          : x
      );
    }
    const isRelation = objectIsRelation(source);
    const startScanFrom = isRelation ? source.fields : source;
    if (isRelation && source.sys.contentType) {
      // save the contentType and ID which we might need:
      // see ComponentList#renderComponents
      source.fields.type = source.sys.contentType.sys.id;
      // Unfortunately we've used the property `id` on our Question contentType
      if (source.fields.type === 'question') {
        source.fields.name = source.fields.id
      }
      source.fields.id = source.sys.id
    }
    return Object.keys(startScanFrom).reduce((cleaned, propName, i) => {
      const propValue = startScanFrom[propName];
      const isObjectOrArray =
        typeof propValue === "object" && propValue !== null;
      cleaned[propName] = isObjectOrArray
        ? cleanContentfulRelations(propValue)
        : propValue;
      return cleaned;
    }, {});
  };

  const updateHeaderFooter = (sourceLocation = {}) => {
    const defaultCalgaryHeaderId = '1yhmHv9UWNoIjMDfT2mydG'
    let headerFooterId = defaultCalgaryHeaderId
    if (!_.isEmpty(sourceLocation) && sourceLocation.headerFooter) {
      headerFooterId = sourceLocation.headerFooter.id
    }

    contentfulClient
      .getEntry(headerFooterId)
      .then(response => {
        const updatedHeaderFooter = getFlattenedEntry(response)
        setHeaderFooter(updatedHeaderFooter)
      })
      .catch(console.error)
  }

  // Setup contentful client
  useEffect(() => {
    setContentfulClient(
      contentful.createClient({
        space: process.env.REACT_APP_CONTENTFUL_SPACE,
        accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN,
        host: process.env.REACT_APP_CONTENTFUL_HOST,
        environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT
      })
    )
  }, [])

  // When the contentfulClient exists, grab all United Way Locations
  useEffect(() => {
    if (_.isEmpty(contentfulClient)) {
      return
    }
    updateHeaderFooter()
    // get all United Way Locations
    contentfulClient
    .getEntries({ content_type: 'unitedWayLocation' })
    .then(response => {

      const unitedWayLocations = cleanContentfulRelations(
        JSON.parse(response.stringifySafe()).items
      )

      setChooseLocation(unitedWayLocations)
      const currentLocation = unitedWayLocations.find(
        location => location.areaServed === userAreaServed
      )
      setUserLocation(currentLocation)
      updateHeaderFooter(currentLocation)
    })
    .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentfulClient])

  useEffect(() => {
    const chosen = chooseLocation.find(location =>
      location.areaServed === userAreaServed
    )
    if (!_.isEmpty(chosen)) {
      setUserLocation(chosen)
      saveLocation(chosen)
      updateHeaderFooter(chosen)
      window.dataLayer.push({'location': chosen.areaServed})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAreaServed])

  // Fetch contentful content
  useEffect(() => {
    if (_.isEmpty(userLocation) || _.isEmpty(contentfulClient)) {
      return
    }

    const { aboutPage, homePage, privacyPage, resourcesPage } = userLocation
    const pageIds = [aboutPage.id, homePage.id, privacyPage.id, resourcesPage.id]
    contentfulClient
      .getEntries({
        include: 3,
        content_type: 'page',
        "sys.id[in]": pageIds.join(',')
      })
      .then(response => {
        const pages = cleanContentfulRelations(
          JSON.parse(response.stringifySafe()).items
        )
        const newStylePages = {
          "": pages.find(p => p.id === userLocation.homePage.id),
          about: pages.find(p => p.id === userLocation.aboutPage.id),
          privacy: pages.find(p => p.id === userLocation.privacyPage.id),
          resources: pages.find(p => p.id === userLocation.resourcesPage.id)
        }
        const newPagesMap = new Map(Object.entries(newStylePages))
        setPages(newPagesMap)
        setHasPagesLoaded(true)
      })
      .catch(console.error)

    // get choose role/concern page content
    contentfulClient
      .getEntries({ content_type: 'choosePage' })
      .then(response => {
        response.items.forEach(item => {
          if (item.fields.roleOrConcern === 'Role') {
            setChooseRole(item.fields)
          } else if (item.fields.roleOrConcern === 'Concern') {
            setChooseConcern(item.fields)
          }
        })
      })
      .catch(console.error)

    // get all the scenarios for location
    const scenarioIds = userLocation.scenarios.map(scenario => scenario.id)
    contentfulClient
      .getEntries({
        include: 3,
        content_type: 'scenario',
        "sys.id[in]": scenarioIds.join(',')
      })
      .then(response => {
        const exampleScenarios = response.items.map(scenario => scenario.fields)
        setScenarios(exampleScenarios)
      })
      .catch(console.error)

    // get extra content
    const extraContentId = userLocation.extraContent.id
    contentfulClient
      .getEntry(extraContentId)
      .then(response => {
        setExtraContent(response.fields)
      })
      .catch(console.error)

    // get final outcomes content
    const finalOutcomesId = userLocation.finalOutcomes.id
    contentfulClient
      .getEntry(finalOutcomesId)
      .then(response => {
        const entry = getFlattenedEntry(response)
        setFinalOutcomes(entry)
      })
      .catch(console.error)

    // get global interstitials
    contentfulClient
      .getEntries({ content_type: 'globalInterstitials' })
      .then(response => {
        setGlobalInterstitials(
          cleanContentfulRelations(
            JSON.parse(response.stringifySafe())
          ).items[0]
        )
      })
      .catch(console.error)

    // Resource Page - Skills Block Entry
    contentfulClient
      .getEntry('5LL8EnaqM89ySUY6OROfeg')
      .then(response => {
        setSkillsContent(cleanContentfulRelations(response))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocation])

  // Get scenario based on role/concern selection
  useEffect(() => {
    if (userRole !== '' && userConcern !== '') {
      let scenario = localStorageScenarios.find(
        storedScenario =>
          userRole.toLowerCase().trim() ===
            storedScenario.role.toLowerCase().trim() &&
          userConcern.toLowerCase().trim() ===
            storedScenario.concern.toLowerCase().trim()
      )

      if (scenario) {
        setFoundScenario(scenario)
      } else {
        scenario = {
          role: userRole.toLowerCase().trim(),
          concern: userConcern.toLowerCase().trim(),
          completedSkillsScore: null,
          completedSkills: null,
          completedMaxScore: null,
          completedScoring: null,
          currentQuestion: null,
          currentSkills: null,
          currentMaxScore: null,
          currentScoring: null,
          currentProgression: 0,
          currentOnEnding: false,
          currentCompletedQuestions: [],
        }
        setFoundScenario(scenario)
        setLocalStorageScenarios(prevState => [...prevState, scenario])
      }
    }
  }, [userRole, userConcern, localStorageScenarios])

  // Save scenarios to localStorage
  useEffect(() => {
    saveScenarios(localStorageScenarios)
  }, [localStorageScenarios])

  const nuclearResetScenario = () => {
    const fromStorage = localStorage.getItem('scenarios')
    const scenarios = JSON.parse(fromStorage)
    const newScenarios = scenarios.map(scenario => {
      scenario.currentOnEnding = false
      scenario.currentMaxScore = []
      scenario.currentProgression = 0
      scenario.currentQuestion = null
      scenario.currentScoring = []
      scenario.currentSkills = []
      return scenario
    })
    localStorage.setItem('scenarios', JSON.stringify(newScenarios))
  }

  // When changes happen to the found scenario, save them in local storage
  useEffect(() => {
    if (!_.isEmpty(localStorageScenarios)) {
      const storedScenarios = localStorageScenarios
      const foundScenarioIndex = storedScenarios.findIndex(
        storedScenario =>
          userRole.toLowerCase().trim() ===
            storedScenario.role.toLowerCase().trim() &&
          userConcern.toLowerCase().trim() ===
            storedScenario.concern.toLowerCase().trim()
      )

      if (foundScenarioIndex >= 0) {
        storedScenarios[foundScenarioIndex] = foundScenario
        saveScenarios(storedScenarios)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundScenario])

  // Storing skills, maxScore, scoring and progression as they are updated in state
  useEffect(() => {
    setFoundScenario(prevState => ({
      ...prevState,
      currentSkills: skills,
    }))
  }, [skills])

  useEffect(() => {
    setFoundScenario(prevState => ({
      ...prevState,
      currentMaxScore: maxScore,
    }))
  }, [maxScore])

  useEffect(() => {
    setFoundScenario(prevState => ({
      ...prevState,
      currentScoring: scoring,
    }))
  }, [scoring])

  useEffect(() => {
    setFoundScenario(prevState => ({
      ...prevState,
      currentProgression: progression,
    }))
  }, [progression])


  return (
    <GlobalContext.Provider
      value={{
        state: {
          scenarios,
          chooseRole,
          chooseConcern,
          chooseLocation,
          userRole,
          userConcern,
          userAreaServed,
          isSimulation,
          hideFooter,
          showCompletionSurvey,
          showExitSurvey,
          showBackButton,
          pages,
          hasPagesLoaded,
          headerFooter,
          extraContent,
          finalOutcomes,
          progression,
          skills,
          maxScore,
          scoring,
          onEnding,
          localStorageScenarios,
          foundScenario,
          contentfulClient,
          globalInterstitals,
          skillsContent,
          userLocation,
          completedQuestions,
        },
        actions: {
          setUserRole,
          setUserConcern,
          setUserAreaServed,
          setIsSimulation,
          setHideFooter,
          setShowCompletionSurvey,
          setShowExitSurvey,
          setShowBackButton,
          setProgression,
          setSkills,
          setMaxScore,
          setScoring,
          setOnEnding,
          setLocalStorageScenarios,
          setFoundScenario,
          saveLastVisitedScenario,
          loadLastVisitedScenario,
          clearLastVisitedScenario,
          addCompletedQuestion,
          clearCompletedQuestions,
          nuclearResetScenario,
        },
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  )
}
