import React from 'react'
import Link from '../link/Link'

const Resource = ({ header = '', links = [] }) => (
  <div className="resource">
    <ul>
      {header && (
        <li>
          <h3>{header}</h3>
        </li>
      )}
      {(links).map((link) => (
        <li key={link.id}>
          <Link {...link} />
        </li>
      ))}
    </ul>
  </div>
)

export default Resource
