import React from 'react'

const Illustration = ({ url = '', alt= '' }) => {

  return (
      <div className="illustration-image">
        <img src={url} alt={alt} />
      </div>

  )
}

export default Illustration