import getIn from 'lodash-es/get'

const getFlattenedEntry = entry => {
  const { sys = {}, fields = {} } = entry || {}
  const contentType = getIn(sys, ['contentType', 'sys', 'id'], '')
  const { id: key = '' } = sys
  return { ...fields, contentType, key, fields }
}

export default getFlattenedEntry
