import React from 'react'
import RichText from '../../RichText'
import Link from '../link/Link'
import DownArrow from '../../../images/down-arrow.svg'

const InfoBlock = ({ leftContent, rightContent, button: button }) => (
  <React.Fragment>
    <div id="info-block" className="info-block">
      <RichText content={leftContent} />
      <div className="natural-supports-info-block">
        <RichText content={rightContent} />
        <Link className="button primary thick-border natural-supports-btn" {...button} />
      </div>
    </div>
    <img
      src={DownArrow}
      alt="Down Arrow"
      className="down-arrow"
      aria-hidden="true"
    />
  </React.Fragment>
)

export default InfoBlock
