import React from 'react'
import RichText from '../../RichText'

const ContentRightImageLeft4060Block = ({ content, image }) => (
  <div className="content-right-image-left-40-60-block">
    <RichText content={content} />
    {image && <img alt={image.description} src={image.file.url}/>}
  </div>
);

export default ContentRightImageLeft4060Block
