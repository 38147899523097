import React, { useContext } from 'react'
import Modal from 'react-modal'
import { GlobalContext } from '../context/GlobalContext'

const Surveys = () => {
  const { state, actions } = useContext(GlobalContext)
  const { extraContent = {} } = state
  const {
    surveyModalExitHeader = '',
    completionModalExitHeader = '',
  } = extraContent

  // close completion modal
  const closeCompletionSurveyModal = () => {
    actions.setShowCompletionSurvey(false)
  }

  // close exit modal
  const closeExitSurveyModal = () => {
    actions.setShowExitSurvey(false)
  }

  return (
    <>
      <Modal
        isOpen={state.showCompletionSurvey}
        onRequestClose={closeCompletionSurveyModal}
        contentLabel="Completion Survey Modal"
        className="modal survey-modal"
        overlayClassName="modal-overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="modal-header">
          <button onClick={closeCompletionSurveyModal} className="close-modal">
            <svg xmlns="http://www.w3.org/2000/svg" width="18.1" height="17">
              <path
                d="M10.5 8.5l7.2-6.8c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L9 7.1 1.7.3C1.3-.1.7-.1.3.3c-.4.4-.4 1 0 1.4l7.2 6.8-7.2 6.8c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.2 0 .5-.1.7-.3L9 9.9l7.3 6.9c.2.2.4.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-7.2-6.9z"
                fill="#131e29"
              />
            </svg>
            <span className="visually-hidden">close</span>
          </button>
          {completionModalExitHeader && <h2>{completionModalExitHeader}</h2>}
        </div>

        <div className="modal-body">
          <div className="scroll-wrapper">
            <iframe
              title="completion-survey"
              src={state.extraContent.surveyLink}
            ></iframe>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={state.showExitSurvey}
        onRequestClose={closeExitSurveyModal}
        contentLabel="Exit Survey Modal"
        className="modal survey-modal"
        overlayClassName="modal-overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="modal-header">
          <button onClick={closeExitSurveyModal} className="close-modal">
            <svg xmlns="http://www.w3.org/2000/svg" width="18.1" height="17">
              <path
                d="M10.5 8.5l7.2-6.8c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L9 7.1 1.7.3C1.3-.1.7-.1.3.3c-.4.4-.4 1 0 1.4l7.2 6.8-7.2 6.8c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.2 0 .5-.1.7-.3L9 9.9l7.3 6.9c.2.2.4.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-7.2-6.9z"
                fill="#131e29"
              />
            </svg>
            <span className="visually-hidden">close</span>
          </button>
          {surveyModalExitHeader && <h2>{surveyModalExitHeader}</h2>}
        </div>

        <div className="modal-body">
          <div className="scroll-wrapper">
            <iframe
              title="exit-survey"
              src={state.extraContent.exitSurveyLink}
            ></iframe>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Surveys
